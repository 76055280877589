const React = require("react");
const TopLeft = require("./TopLeft");
import TEExamPlugin from "@timeedit/te-exam-plugin";
const _ = require("underscore");
const TimeEdit = require("../../lib/TimeEdit");
import ErrorBoundary from "../ErrorBoundary";
import coreAPI from "./ExamCoreAPI";
const Macros = require("../../models/Macros");
const Reservation = require("../../models/Reservation");
const PropTypes = require("prop-types");
const API = require("../../lib/TimeEditAPI");
const Language = require("../../lib/Language");
const Log = require("../../lib/Log");
// Resolved by webpack config
// eslint-disable-next-line import/no-unresolved
import "exam-plugin.css";

class ExamWrapper extends React.Component {
    static contextTypes = {
        targetExternalAppsEnvironment: PropTypes.object,
        user: PropTypes.object,
        registerMacro: PropTypes.func,
        deregisterMacro: PropTypes.func,
        registerExamAPI: PropTypes.func,
        examIdField: PropTypes.number,
        createExamRequest: PropTypes.func,
        clearExamRequest: PropTypes.func,
        usingSSO: PropTypes.bool,
        env: PropTypes.object,
    };

    state = {
        toolbar: null,
        env: this.getEnv(),
        examRequest: null,
        examId: null,
        examRequests: null,
        examIds: null,
    };

    // Can this be cleaned up? Compare to getEnv in Wrapper.jsx (activity manager)
    getEnv() {
        let env = "beta";
        if (
            this.context.targetExternalAppsEnvironment &&
            this.context.targetExternalAppsEnvironment.exam
        ) {
            // eslint-disable-next-line no-console
            console.log(
                "Environment from server: ",
                this.context.targetExternalAppsEnvironment.exam
            );
            env = this.context.targetExternalAppsEnvironment.exam;
        }
        if (
            this.context.env.platformVersion &&
            this.context.env.platformVersion.indexOf("2") !== 0
        ) {
            env = this.context.env.serverEnv;
        }
        if (process.env.NODE_ENV === "development") {
            env = "production";
            // env = "staging";
            if (env !== "staging") {
                // Sanity check/reminder if you're developing against non-staging Preferences
                // eslint-disable-next-line no-console
                console.log(`%cUsing non-development mode for Exam: ${env}`, "color: orange");
            }
        }
        return env;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.state.toolbar !== nextState.toolbar ||
            !_.isEqual(this.props.size, nextProps.size) ||
            !_.isEqual(this.state.examRequest, nextState.examRequest) ||
            !_.isEqual(this.state.examRequests, nextState.examRequests) ||
            !_.isEqual(this.state.env, nextState.env)
        );
    }

    componentDidMount() {
        if (process.env.NODE_ENV === "development") {
            TimeEdit.examAPI = coreAPI(this.props, this.context.user);
        }
        // eslint-disable-next-line no-console
        console.log(`Core Exam plugin environment: ${this.state.env}`);
        this.registerMacros();
        TimeEdit.onCreateExam = this.onCreateExam;
        this.context.registerExamAPI(
            coreAPI(this.props, this.context.user, this.setToolbar.bind(this))
        );
    }
    componentWillUnmount() {
        this.context.deregisterMacro(`examComponent.${this.props.id}`);
        this.context.registerExamAPI(null);
    }
    registerMacros() {
        this.context.registerMacro(`examComponent.${this.props.id}`, {
            events: [Macros.Event.EXAM_REQUEST_UPDATE],
            actions: [
                {
                    key: Macros.Action.UPDATE_EXAM_REQUEST,
                    action: (newRequest) => {
                        if (Array.isArray(newRequest)) {
                            const mappedRequests = newRequest.map((request) =>
                                Object.assign({}, request, {
                                    objects: request.objects.map((obj) =>
                                        Object.assign({}, obj, { typeextid: obj.type.extid })
                                    ),
                                })
                            );
                            // eslint-disable-next-line no-console
                            console.log(mappedRequests);
                            const examIds = this.props.examIdField
                                ? mappedRequests.map(
                                      (request) =>
                                          _.find(
                                              request.fields,
                                              (field) => field.id === this.props.examIdField
                                          )?.values[0]
                                  )
                                : null;
                            this.setState({ examRequests: mappedRequests, examIds });
                        } else {
                            const mappedRequest = Object.assign({}, newRequest, {
                                objects: newRequest.objects.map((obj) =>
                                    Object.assign({}, obj, { typeextid: obj.type.extid })
                                ),
                            });
                            // eslint-disable-next-line no-console
                            console.log(mappedRequest);
                            const examId = this.props.examIdField
                                ? _.find(
                                      newRequest.fields,
                                      (field) => field.id === this.props.examIdField
                                  )?.values[0]
                                : null;
                            this.setState({ examRequest: mappedRequest, examId });
                        }
                    },
                },
            ],
        });
    }
    setToolbar(toolbar) {
        this.setState({ toolbar });
    }
    selectReservation(reservationId) {
        Reservation.get([reservationId], (reservations) => {
            if (reservations.length === 0 || reservations[0] === null) {
                const details = Language.get(
                    "nc_error_no_reservation_found_with_id",
                    reservationId
                );
                Log.error(details);
                return;
            }
            try {
                this.props.fireEvent(
                    `examInCore`,
                    Macros.Event.SELECT_RESERVATION,
                    reservations[0]
                );
                this.props.onEntryInfoOpen([reservationId], false);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        });
    }
    // TODO How to handle multiple reservations being created?
    onCreateExam(examId, reservationId) {
        API.setModifiableReservationFields(
            [reservationId],
            [{ class: "fieldvalue", id: this.context.examIdField, values: [String(examId)] }],
            // eslint-disable-next-line no-unused-vars
            (result) => {
                this.props.fireEvent(
                    `examInCore`,
                    Macros.Event.RESERVATION_MADE_OR_MODIFIED,
                    [reservationId],
                    false,
                    true
                );
            }
        );
    }

    // TODO How to handle multiple reservations being created?
    onReservationUpdated(reservationId) {
        this.props.fireEvent(
            `examInCore`,
            Macros.Event.RESERVATION_MADE_OR_MODIFIED,
            [reservationId],
            false,
            true
        );
    }

    // TODO DOes this need to handle multiple reservations? If so: how?
    onEditExam(reservationId) {
        // eslint-disable-next-line no-console
        console.log(reservationId);
        // Invoke editing in the same way as from Entry.jsx, line 558
        if (reservationId) {
            API.getReservationsByExtid([reservationId], (result) => {
                if (result.length > 0) {
                    this.context.createExamRequest(result[0]);
                } else {
                    this.context.clearExamRequest();
                    this.setState({ examId: null, examRequest: null });
                }
            });
        } else {
            this.context.clearExamRequest();
            this.setState({ examId: null, examRequest: null });
        }
    }

    render() {
        const examAPI = coreAPI(this.props, this.context.user, this.setToolbar.bind(this));
        let env = this.state.env;
        if (env === "beta" && window.location.pathname.indexOf("beta_nl_uu") !== -1) {
            env = "production";
        }
        const reservations =
            (this.state.examRequest ? [this.state.examRequest] : this.state.examRequests) || [];
        let mode = "ALL";
        if (reservations.length > 0) {
            if (reservations.length === 1 && this.state.examId) {
                mode = "EDIT";
            } else if (reservations.length > 1) {
                mode = "CREATE_MULTI";
            } else {
                mode = "CREATE";
            }
        }
        return (
            <div className={_.classSet(this.props.classes)} style={this.props.style}>
                <TopLeft {...this.props} contents={this.state.toolbar} />
                <ErrorBoundary>
                    <div style={{ flex: "1", overflow: "auto" }}>
                        <TEExamPlugin
                            mixpanel={window.mixpanel}
                            {...this.props}
                            usingSSO={this.context.usingSSO}
                            platformVersion={this.context.env.platformVersion}
                            region={this.context.env.serverRegion}
                            env={env}
                            requestData={{ reservations, mode }}
                            showInCalendar={this.selectReservation.bind(this)}
                            onCreateExam={this.onCreateExam.bind(this)}
                            onEditExam={this.onEditExam.bind(this)}
                            onReservationUpdated={this.onReservationUpdated.bind(this)}
                            coreAPI={examAPI}
                            setToolbar={this.setToolbar.bind(this)}
                        />
                    </div>
                </ErrorBoundary>
            </div>
        );
    }
}

module.exports = ExamWrapper;
