const _ = require("underscore");
const CSRF = require("./CSRF");
const Language = require("./Language");
const pkg = require("../../package.json");
const path = require("path");
import * as Sentry from "@sentry/browser";

const EVENTS = [
    "slowResponse",
    "slowerResponse",
    "timeout",
    "requestSent",
    "complete",
    "updateAvailable",
    "abort",
    "error",
];
const SLOW_TIMEOUT_MS = 300;
const VERY_SLOW_TIMEOUT_MS = 2000;
const SLOW_OPERATION_TIMEOUT_MS = 60000;

const XHR_STATE_COMPLETE = 4;

const HTTP_OK = 200;
const HTTP_BAD_REQUEST = 400;
const HTTP_UNAUTHORIZED = 401;
const HTTP_TOO_MANY_REQUESTS = 429;
const HTTP_INTERNAL_SERVER_ERROR = 500;
const HTTP_GATEWAY_TIMEOUT = 504;

const getResponseFor = function (methodName, responses) {
    const response = _.find(
        responses,
        (item) => item.class === "methodresult" && item.name === methodName
    );
    return response || responses;
};

const checkForErrors = function (method, responses) {
    const errorResponse = _.find(responses, (item) => item.class === "error");
    if (errorResponse) {
        const e = new Error(
            Language.get(
                "nc_api_error_from_timeedit",
                `${errorResponse.code}, ${errorResponse.message}`,
                method
            )
        );
        Sentry.captureException(e, {
            extra: { response: errorResponse.message },
            tags: { method },
        });
        throw e;
    }
};

let currentlyProcessedMessage;
let versionThrownAlready = false;
let loginShownAlready = false;

const MessageDispatcher = function (url, timeout, unauthorizedCallback) {
    this.url = url;
    this.timeout = timeout;
    this.unauthorizedCallback = unauthorizedCallback;
    this.cache = {};
    this.eventHandlers = {};
    this.useSSO = false;
};

MessageDispatcher.prototype.on = function (event, callback) {
    if (EVENTS.indexOf(event) === -1) {
        throw new Error(`No event called ${event} in MessageDispatcher.`);
    }

    if (!this.eventHandlers.hasOwnProperty(event)) {
        this.eventHandlers[event] = [];
    }
    this.eventHandlers[event].push(callback);
};

MessageDispatcher.prototype.fire = function (event, data) {
    if (!this.eventHandlers.hasOwnProperty(event)) {
        return;
    }

    this.eventHandlers[event].forEach((callback) => callback(data));
};

let _id = 0;

const nextId = () => {
    _id++;
    return _id;
};

MessageDispatcher.prototype.send = function (
    name,
    parameters,
    callback,
    isLongOperation = false,
    errorCallback = _.noop
) {
    if (!parameters) {
        throw new Error("Messages can not be sent without parameters.");
    }

    if (
        currentlyProcessedMessage &&
        currentlyProcessedMessage.name === name &&
        _.isEqual(currentlyProcessedMessage.parameters, parameters)
    ) {
        throw new Error("Identical API calls may not be sent within the same callback chain.");
    }

    if (!CSRF.hasToken()) {
        const isSecure = process.env.NODE_ENV !== "development";
        const tokenSetResult = CSRF.updateToken(isSecure);
        if (tokenSetResult instanceof Error) {
            Sentry.captureException(tokenSetResult, {
                extra: { response: "Token setting failed" },
                tags: { method: name },
            });
        }
    }

    const metadata = {
        system_lang: true,
        language: navigator.languages ? navigator.languages[0] : navigator.language,
        isLongOperation,
    };

    if (name === "exportObjects" || parameters.useExtid === true) {
        // eslint-disable-next-line no-param-reassign
        delete parameters.useExtid;
        metadata.use_extid = true;
    }

    const message = {
        method: name,
        parameters,
        metadata,
        id: nextId(),
    };
    const messageCache = this.getMessageCache(message);
    messageCache.callbacks.push(callback);
    if (messageCache.parameters !== null) {
        return;
    }
    messageCache.parameters = parameters;

    const slowTimeout = isLongOperation
        ? 0
        : setTimeout(this.fire.bind(this, "slowResponse"), SLOW_TIMEOUT_MS);
    const verySlowTimeout = isLongOperation
        ? 0
        : setTimeout(
              this.fire.bind(this, "slowerResponse", { id: message.id }),
              VERY_SLOW_TIMEOUT_MS
          );

    const req = new XMLHttpRequest();
    const self = this;

    // Assumes three-part versions passed in as strings, i.e. '1.20.1'
    // NOT semantic, all local versions greater than or equal to server satisfies
    const isNeededVersion = function (localVersion, serverProvidedVersion) {
        const localVersionParts = localVersion.split(".").map((part) => parseInt(part, 10));
        const serverVersionParts = serverProvidedVersion
            .split(".")
            .map((part) => parseInt(part, 10));
        if (localVersionParts.length !== serverVersionParts.length) {
            return false;
        }
        if (localVersionParts[0] > serverVersionParts[0]) {
            return true;
        }
        if (localVersionParts[0] === serverVersionParts[0]) {
            if (localVersionParts[1] > serverVersionParts[1]) {
                return true;
            }
            if (
                localVersionParts[1] === serverVersionParts[1] &&
                localVersionParts[2] >= serverVersionParts[2]
            ) {
                return true;
            }
        }
        return false;
    };

    req.onreadystatechange = function () {
        if (req.readyState !== XHR_STATE_COMPLETE) {
            return;
        }
        const completedTime = Date.now();

        clearTimeout(slowTimeout);
        clearTimeout(verySlowTimeout);

        const currentMessageCache = self.getMessageCache(message);
        if (req.status === HTTP_OK) {
            const availableVersion = req.getResponseHeader("X-TimeEdit-Client-Version");
            const requiredVersion =
                req.getResponseHeader("X-TimeEdit-Client-Version-Compatibility") ||
                availableVersion;

            if (
                requiredVersion &&
                !isNeededVersion(pkg.version, requiredVersion) &&
                !versionThrownAlready
            ) {
                versionThrownAlready = true;
                window.alert(Language.get("nc_client_update_needed")); // eslint-disable-line no-alert
                if (process.env.NODE_ENV !== "development") {
                    document.location.reload(true);
                    return;
                }
            }

            if (
                availableVersion &&
                !isNeededVersion(pkg.version, availableVersion) &&
                self.eventHandlers.hasOwnProperty("updateAvailable")
            ) {
                self.fire("updateAvailable");
            }

            let responseObject = JSON.parse(req.response);

            responseObject = responseObject.map((res) =>
                _.extend(res, {
                    time: _.extend(res.time, {
                        rtt: completedTime - message.time,
                    }),
                })
            );

            checkForErrors(name, responseObject);
            const result = getResponseFor(name, responseObject);
            currentlyProcessedMessage = message;
            self.removeMessageCache(message);
            currentMessageCache.callbacks.forEach((cachedCallback) => {
                try {
                    cachedCallback(result); // Assume we deal in JSON from TimeEdit.
                } catch (e) {
                    Sentry.captureException(e, {
                        extra: { response: result },
                        tags: { method: name },
                    });
                    throw e;
                }
            });
            currentlyProcessedMessage = null;
            self.fire("complete", { status: req.status, response: result, id: message.id });
            return;
        }
        self.fire("complete", { status: req.status, id: message.id });

        if (req.status === HTTP_BAD_REQUEST) {
            if (req.response.indexOf("(-109)") !== -1 && !loginShownAlready) {
                loginShownAlready = true;
                window.alert(Language.get("nc_server_has_ended_session.")); // eslint-disable-line no-alert
                if (this.useSSO) {
                    // We set onLogout from App.jsx as soon as the component mounts - this will call SSO logout and destroy token
                    if (this.onLogout) {
                        this.onLogout();
                    }
                } else {
                    const BASE_URL = path.resolve("/", window.TIMEEDIT_APP_PATH);
                    document.location.href = `${BASE_URL}/logout`;
                }
            } else {
                // eslint-disable-next-line no-undef
                /*mixpanel.track("Bad server response", {
                Response: req.response,
                Method: message.method,
            });*/
                Sentry.captureMessage(`${message.method} - bad server response`, {
                    extra: { response: req.response },
                    tags: { method: message.method },
                });
                self.fire("error", { method: name, id: message.id });
                self.removeMessageCache(message);
                errorCallback("error", message, req.response);
            }
        }

        if (req.status === HTTP_UNAUTHORIZED) {
            self.unauthorizedCallback();
            return;
        }

        if (req.status === HTTP_TOO_MANY_REQUESTS) {
            // Too many requests, API call limit exceeded
            const e = new Error(Language.get("nc_api_error_from_timeedit", req.response, name));
            Sentry.captureException(e, {
                extra: { response: req.response },
                tags: { method: message.method },
            });
            throw e;
        }

        if (req.status === HTTP_INTERNAL_SERVER_ERROR) {
            self.removeMessageCache(message);
            const e = new Error("Server error.");
            Sentry.captureException(e, {
                extra: { response: req.response },
                tags: { method: message.method },
            });
            currentMessageCache.callbacks.forEach((cb) => cb(e));
        }
        if (req.status === HTTP_GATEWAY_TIMEOUT) {
            self.removeMessageCache(message);
            const e = new Error("Server error.");
            Sentry.captureException(e, {
                extra: { response: req.response },
                tags: { method: message.method },
            });
            currentMessageCache.callbacks.forEach((cb) => cb(e));
        }
    };
    req.open("post", this.url, true);
    if (this.timeout) {
        req.timeout = this.timeout;
    }
    if (isLongOperation) {
        req.timeout = SLOW_OPERATION_TIMEOUT_MS;
    }
    if (parameters.timeout !== undefined) {
        req.timeout = parameters.timeout;
    }
    const sentryContext = {
        extra: { id: message.id },
        tags: { method: name },
    };
    req.ontimeout = function () {
        Sentry.captureMessage("Timeout", sentryContext);
        self.fire("timeout", { method: name, id: message.id, handled: errorCallback !== _.noop });
        self.removeMessageCache(message);
        errorCallback("timeout", message);
    };
    req.onabort = function () {
        Sentry.captureMessage("Abort", sentryContext);
        self.fire("abort", { method: name, id: message.id });
        self.removeMessageCache(message);
        errorCallback("abort", message);
    };
    req.onerror = function () {
        Sentry.captureMessage("Error", sentryContext);
        self.fire("error", { method: name, id: message.id });
        self.removeMessageCache(message);
        errorCallback("error", message);
    };
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("X-CSRF-TOKEN", CSRF.getToken());
    message.time = Date.now();
    req.send(JSON.stringify(_.omit(message, ["id", "time"])));
    self.fire("requestSent", { id: message.id });
};

MessageDispatcher.prototype.getMessageCache = function (message) {
    if (!this.cache.hasOwnProperty(message.method)) {
        this.cache[message.method] = [];
    }

    let messageCache = _.find(this.cache[message.method], (cacheItem) =>
        _.isEqual(cacheItem.parameters, message.parameters)
    );

    if (!messageCache) {
        messageCache = { parameters: null, callbacks: [] };
        this.cache[message.method].push(messageCache);
    }

    return messageCache;
};

MessageDispatcher.prototype.removeMessageCache = function (message) {
    this.cache[message.method] = this.cache[message.method].filter(
        (cacheItem) => !_.isEqual(cacheItem.parameters, message.parameters)
    );
};

module.exports = MessageDispatcher;
