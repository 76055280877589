const API = require("../lib/TimeEditAPI");
const Selection = require("./Selection");
import {
    MillenniumDate,
    MillenniumDateTime,
    MillenniumTime,
    MillenniumWeek,
    MillenniumWeekday,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
const _ = require("underscore");
const Model = require("./Model");
const TemplateKind = require("./TemplateKind");
const FunctionMode = require("./FunctionMode");
const Language = require("../lib/Language");
const PeriodHeader = require("./PeriodHeader");
const TimePeriodHeader = require("./TimePeriodHeader");
const DateHeader = require("./DateHeader");
const TimeHeader = require("./TimeHeader");
const ObjectHeader = require("./ObjectHeader");
const Log = require("../lib/Log");
import { ClusterKind } from "../lib/EntryConstants";
import { isLegalGroup } from "../lib/GroupUtils";
const WeekHeader = require("../models/WeekHeader");
const WeekdayHeader = require("../models/WeekdayHeader");
const Weekperiod = require("./WeekPeriodHeader");
const Weekdayperiod = require("./WeekdayPeriodHeader");
const Dateperiod = require("./DatePeriodHeader");
const Timeperiod = require("./TimePeriodHeader");
const Settings = require("./Settings");
import { TCalendar } from "../types/TCalendar";
import { TimeConstants as TC } from "../lib/TimeConstants";
import { Entry as TSEntry } from "./Entry";
import { TEntry } from "../types/TEntry";
const Entry: typeof TSEntry = require("./Entry");
import { Limits as TSLimits } from "./Limits";
const Limits: typeof TSLimits = require("./Limits");

const getDefaultDate = function (limits) {
    const today = MillenniumDate.today();
    if (today.isBefore(limits.getStartDate()) || today.isAfter(limits.getEndDate())) {
        return limits.getStartDate();
    }
    return today;
};

const MACRO_IDS = {
    SET_DAY: 1,
    SCROLL_DAY: 2,
};

export const Calendar = function (this: TCalendar, xHeader, yHeader, limits) {
    Model.call(this, "Calendar");
    this.readOnly = false;
    this.hideInfoReservations = false;
    this.privateSelected = false;
    this.selection = null;
    this.templateKind = TemplateKind.RESERVATION;
    this.mode = FunctionMode.RESERVATION;
    this.hideObstacles = false;
    this.hideAbstractObstacles = false;
    this.availabilityInForeground = false;
    this.macroFunctions = [];
    this.limits = limits;
    this.xHeader = xHeader;
    this.yHeader = yHeader;
    this.setDayMacro = false;
    this.scrollDayMacro = false;
    this.setTimeMacro = false;
    this.scrollTimeMacro = false;
    this.setDataMacro = false;
    this.navButtonsVisible = true;
    this.useSpotlight = false;
    this.spotlightDate = null;
    this.firstVisibleDate = getDefaultDate(limits); // Fallback for calendars without a date providing header
    this.typeFilter = [];
    this.colorTypes = [];
    this.selected = false;
    this.selectionGroup = [];
    this.entries = [];
    this.isUnsupported = false;
    this.isTimePeriod = false;
    this.periodId = 0;
    this.isPaddingActive = true;
};

Calendar.prototype = Object.create(Model.prototype);

Calendar.prototype.getVisibleDates = function (indexes = [], isDatePeriodAlwaysSimple = true) {
    const headerMap = this.getHeaderTypeMap();
    const periodHeaders = this.getPeriodHeaders();
    if (indexes.length === 0) {
        // eslint-disable-next-line no-param-reassign
        indexes = periodHeaders.map((header) => _.range(0, header.visibleValues));
    }

    if (indexes.length !== periodHeaders.length) {
        throw new Error("Indexes must be of equal length to the number of period headers.");
    }

    if (headerMap.date) {
        return headerMap.date.getVisibleValues(false);
    }

    const getPeriodValuesForTypeName = (name) => {
        const header = headerMap[name];
        if (header.isSimplePeriod() || (name === "dateperiod" && isDatePeriodAlwaysSimple)) {
            // TODO Shouldn't returning all values flattened *always* be the right thing to do?
            return _.flatten(header.getValues());
        }

        for (let i = 0; i < periodHeaders.length; i++) {
            if (periodHeaders[i] === header) {
                return header.valueAt(indexes[i]);
            }
        }
        return null;
    };

    if (headerMap.dateperiod) {
        return getPeriodValuesForTypeName("dateperiod");
    }

    // No date header means we have to find partial providers
    let weekValues, weekdayValues;
    if (headerMap.week) {
        weekValues = headerMap.week.getVisibleValues();
        weekdayValues = headerMap.week.weekdays;
    } else if (headerMap.weekperiod) {
        weekValues = getPeriodValuesForTypeName("weekperiod");
        weekdayValues = headerMap.weekperiod.weekdays;
    }

    if (headerMap.weekday) {
        weekdayValues = headerMap.weekday.getVisibleValues();
        weekValues = weekValues || headerMap.weekday.weeks;
    } else if (headerMap.weekdayperiod) {
        weekdayValues = getPeriodValuesForTypeName("weekdayperiod");
        weekValues = weekValues || headerMap.weekdayperiod.weeks;
    }

    if (!weekdayValues || !weekValues) {
        return [this.firstVisibleDate];
    }

    try {
        let result = MillenniumDateTime.createFromList([weekValues, weekdayValues]);
        if (!Array.isArray(result)) {
            result = [result];
        }
        return result.map((datetime) => datetime.getMillenniumDate());
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return [];
    }
};

Calendar.prototype.setLimits = function (limits) {
    if (!(limits instanceof Limits)) {
        // eslint-disable-next-line no-param-reassign
        limits = new Limits(limits);
    }

    const calendar = this.immutableSet({
        limits,
    });

    const xHeader = this.xHeader.setLimits(calendar.limits);
    const yHeader = this.yHeader.setLimits(calendar.limits);

    return calendar.immutableSet({
        xHeader,
        yHeader,
    });
};

Calendar.prototype.setPeriodId = function (periodId) {
    return this.immutableSet({ periodId });
};

Calendar.prototype.setIsTimePeriod = function (isTimePeriod) {
    return this.immutableSet({ isTimePeriod });
};

Calendar.prototype.setLimit = function (limit, value) {
    const limits = new Limits(this.limits);
    limits[limit] = value;
    return this.setLimits(limits);
};

Calendar.prototype.getFirstVisibleTime = function () {
    const timeHeader = this.getHeader(TimeHeader);
    if (timeHeader) {
        return new MillenniumTime(timeHeader.getVisibleValues()[0]);
    }
    return new MillenniumTime(0);
};

Calendar.prototype.getLastVisibleTime = function () {
    const timeHeader = this.getHeader(TimeHeader);
    if (timeHeader) {
        const values = timeHeader.getVisibleValues();
        return new MillenniumTime(values[values.length - 1]);
    }
    // eslint-disable-next-line no-magic-numbers
    return new MillenniumTime(23, 59, 59);
};

Calendar.prototype.getHeaders = function () {
    return this.xHeader.getHeaders().concat(this.yHeader.getHeaders());
};

Calendar.prototype.getHeaderTypeMap = function () {
    const map = {
        week: WeekHeader,
        weekday: WeekdayHeader,
        date: DateHeader,
        time: TimeHeader,
        object: ObjectHeader,
        weekperiod: Weekperiod,
        weekdayperiod: Weekdayperiod,
        dateperiod: Dateperiod,
        timeperiod: Timeperiod,
    };

    const headers = this.getHeaders();
    Object.getOwnPropertyNames(map).forEach((name) => {
        map[name] = _.find(headers, (header) => header instanceof map[name]);
    });
    return map;
};

Calendar.prototype.getHeader = function (headerModel) {
    return _.find(this.getHeaders(), (header) => header instanceof headerModel);
};

Calendar.prototype.hasHeaderType = function (headerModel) {
    return this.getHeader(headerModel) !== undefined;
};

Calendar.prototype.getActiveHeader = function (useXAxis) {
    const header = useXAxis ? this.xHeader : this.yHeader;
    return _.find(header.getHeaders(), (hdr) => hdr.isActive === true);
};

Calendar.prototype.getProviderMap = function () {
    const map = this.getHeaderTypeMap();

    return {
        time: map.time !== undefined || map.timeperiod !== undefined,
        date: map.date !== undefined || map.dateperiod !== undefined,
        week: map.week !== undefined || map.weekperiod !== undefined,
        weekday: map.weekday !== undefined || map.weekdayperiod !== undefined,
    };
};

Calendar.prototype.hasDayProvider = function () {
    const map = this.getProviderMap();
    return map.date || map.week || map.weekday;
};

Calendar.prototype.getEntryIndexes = function (entry, useXHeader, onlyVisible) {
    const header = useXHeader ? this.xHeader : this.yHeader;
    return header.getHeaders().map((hdr) => hdr.indexOf(entry, onlyVisible));
};

// Return an array of MillenniumDateTime. position: x/y relative to top left of calendar
Calendar.prototype.getTimeFromIndexes = function (
    xIndexes,
    yIndexes,
    isStartTime,
    onlyVisible = true
) {
    let values: any[] = [];
    const headers = this.getHeaderTypeMap();

    const getValues = function (indexes, header, index) {
        if (!(header instanceof ObjectHeader)) {
            values.push(header.valueAt(indexes[index], onlyVisible));
        }
    };
    this.xHeader.getHeaders().forEach(getValues.bind(null, xIndexes));
    this.yHeader.getHeaders().forEach(getValues.bind(null, yIndexes));

    if (headers.week && !headers.weekday && !headers.weekdayperiod) {
        values.push(headers.week.weekdays);
    } else if (headers.weekday && !headers.week && !headers.weekperiod) {
        values.push(headers.weekday.weeks);
    } else if (headers.weekperiod && !headers.weekday && !headers.weekdayperiod) {
        values.push(headers.weekperiod.weekdays);
    } else if (headers.weekdayperiod && !headers.week && !headers.weekperiod) {
        values.push(headers.weekdayperiod.weeks);
    } else if (
        !(
            headers.date ||
            headers.week ||
            headers.weekday ||
            headers.weekperiod ||
            headers.weekdayperiod ||
            headers.dateperiod
        )
    ) {
        values.push(this.firstVisibleDate);
    }

    if (headers.timeperiod) {
        values = values.map((val) => {
            // eslint-disable-next-line no-param-reassign
            val = Array.isArray(val) ? val : [val];
            if (val.length === 0 || val[0] === undefined) {
                return val;
            }
            if (val[0].hasOwnProperty("start") && val[0].hasOwnProperty("end")) {
                return val.map((range) => range[isStartTime ? "start" : "end"]);
            }
            return val;
        });
    }

    if (!headers.time && !headers.timeperiod) {
        if (isStartTime) {
            values.push(this.limits.getStartTime());
        } else {
            values.push(this.limits.getEndTime());
        }
    }

    return values;
};

Calendar.prototype.getEntryTimeSlots = function (entry) {
    const entryIndexesX = this.getEntryIndexes(entry, true, false);
    const entryIndexesY = this.getEntryIndexes(entry, false, false);
    const startTimeslots = this.getTimeFromIndexes(entryIndexesX, entryIndexesY, true, false);
    try {
        return _.asArray(MillenniumDateTime.createFromList(startTimeslots));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return [];
    }
};

Calendar.prototype.getEntryWithTimeSlots = function (entry) {
    const startTimeSlots = this.getEntryTimeSlots(entry);
    if (startTimeSlots.length === entry.reservationids.length) {
        return _.extend({}, entry);
    }

    // Map reservation ids to available time slots, with 0 meaning no entry for the corresponding slot
    return _.extend({}, entry, {
        clusterReservationIds: startTimeSlots.map((slot) => {
            const index = entry.startTimes.findIndex(
                (startTime) => slot.getMts() === startTime.getMts()
            );
            return index > -1 ? entry.reservationids[index] : 0;
        }),
        startTimes: startTimeSlots,
        endTimes: startTimeSlots.map((start) => start.addSeconds(entry.getLength())),
    });
};

Calendar.prototype.getObjectsFromIndexes = function (xIndexes, yIndexes) {
    const values: any[] = [];
    const getValues = function (indexes, header, index) {
        if (header instanceof ObjectHeader) {
            const headerObject = header.valueAt(indexes[index]);
            if (headerObject) {
                values.push({
                    id: headerObject.id,
                    name: headerObject.name,
                    type: header.searchCriteria.type,
                });
            }
        }
    };
    this.xHeader.getHeaders().forEach(getValues.bind(null, xIndexes));
    this.yHeader.getHeaders().forEach(getValues.bind(null, yIndexes));
    return values;
};

Calendar.prototype.getPeriodFromIndexes = function (xIndexes, yIndexes) {
    const periods = {};

    const getValues = function (indexes, header, index) {
        if (header instanceof PeriodHeader) {
            periods[header.getId()] = indexes[index];
        }
    };
    this.xHeader.getHeaders().forEach(getValues.bind(null, xIndexes));
    this.yHeader.getHeaders().forEach(getValues.bind(null, yIndexes));
    return periods;
};

// Note that the time is always the start of the day
Calendar.prototype.getStartDateTime = function (indexes, isDatePeriodAlwaysSimple = true) {
    const dates = this.getVisibleDates(indexes, isDatePeriodAlwaysSimple);
    let periodIndex = -1;
    const timePeriodHeader = _.find(this.getPeriodHeaders(), (header) => {
        periodIndex++;
        return header instanceof TimePeriodHeader;
    });

    try {
        if (!timePeriodHeader) {
            const timeHeader = this.getHeader(TimeHeader);
            if (!timeHeader) {
                return MillenniumDateTime.createFromList([dates, this.limits.getStartTime()]);
            }
            return MillenniumDateTime.createFromList([dates, timeHeader.valueAt(0)]);
        }

        const startTimes = timePeriodHeader
            .valueAt(indexes[periodIndex])
            .map((range) => range.start);
        return MillenniumDateTime.createFromList([dates, startTimes]);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return [];
    }
};

// Note that the time is always the end of the day
Calendar.prototype.getEndDateTime = function (indexes, isDatePeriodAlwaysSimple = true) {
    const dates = this.getVisibleDates(indexes, isDatePeriodAlwaysSimple);
    let periodIndex = -1;
    const timePeriodHeader = _.find(this.getPeriodHeaders(), (header) => {
        periodIndex++;
        return header instanceof TimePeriodHeader;
    });

    try {
        if (!timePeriodHeader) {
            const timeHeader = this.getHeader(TimeHeader);
            if (!timeHeader) {
                return MillenniumDateTime.createFromList([dates, this.limits.getEndTime()]);
            }
            return MillenniumDateTime.createFromList([
                dates,
                new MillenniumTime(timeHeader.getEndTime(true)),
            ]);
        }

        const endTimes = timePeriodHeader.valueAt(indexes[periodIndex]).map((range) => range.end);
        return MillenniumDateTime.createFromList([dates, endTimes]);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return [];
    }
};

Calendar.prototype.getHeaderObjects = function () {
    let objects: any[] = [];
    this.getHeaders()
        .filter((header) => header instanceof ObjectHeader)
        .forEach((header) => {
            const visibleObjects = header.getVisibleValues().map((item) => item.id);
            if (visibleObjects.length === 0) {
                return;
            }

            objects = objects.concat({
                objects: visibleObjects,
                typeId: header.searchCriteria.type,
            });
        });

    return objects;
};

Calendar.prototype.getClusterValues = function (periodCombination = []) {
    const headers = this.getHeaderTypeMap();
    const hasPeriodValues = (header) => !_.isNullish(periodCombination[header.getId()]);
    const getPeriodValues = (header) => header.getValues()[periodCombination[header.getId()]];

    if (
        headers.date ||
        (headers.week && headers.weekday) ||
        (headers.weekday && !headers.weekperiod && headers.weekday.weeks.length === 1) ||
        (headers.weekday && headers.weekperiod && headers.weekperiod.isSimplePeriod()) ||
        (headers.week && !headers.weekdayperiod && headers.week.weekdays.length === 1) ||
        (headers.week && headers.weekdayperiod && headers.weekdayperiod.isSimplePeriod())
    ) {
        return [];
    }

    if (headers.week) {
        if (headers.weekdayperiod && hasPeriodValues(headers.weekdayperiod)) {
            return getPeriodValues(headers.weekdayperiod);
        }
        return headers.week.weekdays;
    }

    if (headers.weekday) {
        if (headers.weekperiod && hasPeriodValues(headers.weekperiod)) {
            return getPeriodValues(headers.weekperiod);
        }
        return headers.weekday.weeks;
    }

    if (headers.dateperiod) {
        if (headers.dateperiod.isSimplePeriod()) {
            return [];
        }
        if (hasPeriodValues(headers.dateperiod)) {
            return getPeriodValues(headers.dateperiod);
        }
    }

    const getMillenniumDates = (list) => {
        try {
            return MillenniumDateTime.createFromList(list).map((date) => date.getMillenniumDate());
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            return [];
        }
    };
    if (headers.weekperiod && !headers.weekdayperiod) {
        if (headers.weekperiod.isSimplePeriod()) {
            if (headers.weekperiod.weekdays.length === 1) {
                return [];
            }
            return headers.weekperiod.weekdays;
        }

        if (hasPeriodValues(headers.weekperiod)) {
            if (headers.weekperiod.weekdays.length === 1) {
                return getPeriodValues(headers.weekperiod);
            }

            return getMillenniumDates([
                getPeriodValues(headers.weekperiod),
                headers.weekperiod.weekdays,
            ]);
        }
    }

    if (headers.weekdayperiod && !headers.weekperiod) {
        if (headers.weekdayperiod.isSimplePeriod()) {
            if (headers.weekdayperiod.weeks.length === 1) {
                return [];
            }
            return headers.weekdayperiod.weeks;
        }

        if (hasPeriodValues(headers.weekdayperiod)) {
            if (headers.weekdayperiod.weeks.length === 1) {
                return getPeriodValues(headers.weekdayperiod);
            }

            return getMillenniumDates([
                getPeriodValues(headers.weekdayperiod),
                headers.weekdayperiod.weeks,
            ]);
        }
    }

    if (headers.weekperiod && headers.weekdayperiod) {
        if (headers.weekperiod.isSimplePeriod() && headers.weekdayperiod.isSimplePeriod()) {
            return [];
        }

        if (
            headers.weekperiod.isSimplePeriod() &&
            !headers.weekdayperiod.isSimplePeriod() &&
            hasPeriodValues(headers.weekdayperiod)
        ) {
            return getPeriodValues(headers.weekdayperiod);
        }

        if (
            !headers.weekperiod.isSimplePeriod() &&
            hasPeriodValues(headers.weekperiod) &&
            headers.weekdayperiod.isSimplePeriod()
        ) {
            return getPeriodValues(headers.weekperiod);
        }

        if (hasPeriodValues(headers.weekperiod) && hasPeriodValues(headers.weekdayperiod)) {
            return getMillenniumDates([
                getPeriodValues(headers.weekperiod),
                getPeriodValues(headers.weekdayperiod),
            ]);
        }
    }

    return [];
};

Calendar.prototype.getClusterKind = function () {
    const combos = this.getPeriodCombinations();
    const clusterValues = this.getClusterValues(combos[0]);

    if (clusterValues.length === 0) {
        return ClusterKind.NONE;
    }

    if (clusterValues[0] instanceof MillenniumWeek) {
        return ClusterKind.WEEK;
    }

    if (clusterValues[0] instanceof MillenniumWeekday) {
        return ClusterKind.WEEKDAY;
    }

    if (clusterValues[0] instanceof MillenniumDate) {
        return ClusterKind.DATE;
    }
    return ClusterKind.NONE;
};

Calendar.prototype.getClusterDepth = function (periodCombination) {
    const clusterValues = this.getClusterValues(periodCombination);
    if (clusterValues.length === 0) {
        return 1;
    }

    return clusterValues.length;
};

Calendar.prototype.getMaxClusterDepth = function () {
    const combos = this.getPeriodCombinations();
    const clusterValues =
        combos.length > 0
            ? combos.map((combo) => this.getClusterValues(combo))
            : [this.getClusterValues()];
    return clusterValues.reduce((max, values) => Math.max(max, values.length), 1);
};

Calendar.prototype.isCluster = function () {
    return this.getMaxClusterDepth() > 1;
};

Calendar.prototype.switchHeaders = function () {
    const self = this;
    return this.immutableSet({
        xHeader: self.yHeader.resetSize(),
        yHeader: self.xHeader.resetSize(),
    });
};

Calendar.prototype.gotoOrder = function (orderDef) {
    const begin = new MillenniumDateTime(orderDef.order_begin).getMillenniumDate();
    const end = new MillenniumDateTime(orderDef.order_end).getMillenniumDate();
    const firstVisible = this.getFirstVisibleDate();

    let allVisible;
    try {
        allVisible = this.getVisibleDates();
    } catch (e) {
        // If we cannot get visible dates (e.g. there are period headers)
        return this;
    }

    if (_.some(allVisible, (date) => date.getDayNumber() === end.getDayNumber())) {
        return this;
    }
    if (_.some(allVisible, (date) => date.getDayNumber() === begin.getDayNumber())) {
        return this;
    }
    if (begin.isBefore(firstVisible) && !end.isBefore(firstVisible)) {
        return this;
    }
    return this.gotoDateTime(begin);
};

Calendar.prototype.gotoReservation = function (reservation, spotlight = true) {
    if (!reservation || !reservation.begin) {
        // Do nothing if the reservation lacks time, i.e. is on the waiting list
        return this;
    }

    const begin = new MillenniumDateTime(reservation.begin).getHours();
    const end = new MillenniumDateTime(reservation.end).getHours();
    const beginDay = new MillenniumDateTime(reservation.begin).getMillenniumDate().getDayNumber();
    const endDay = new MillenniumDateTime(reservation.end).getMillenniumDate().getDayNumber();
    const beginTime = new MillenniumDateTime(reservation.begin).getMillenniumTime();
    const endTime =
        beginDay === endDay
            ? new MillenniumDateTime(reservation.end).getMillenniumTime()
            : new MillenniumDateTime(reservation.begin).getEndOfDay().getMillenniumTime();

    const getFirstVisibleValue = (headers) => {
        for (let i = 0; i < headers.length; i++) {
            if (headers[i] instanceof TimeHeader) {
                const first = headers[i].firstVisibleValue;
                const last = first + headers[i].visibleValues;
                if (
                    (begin >= first && begin < last) ||
                    (end > first && end < last) ||
                    (begin <= first && end > first) ||
                    (begin > first && begin < last && beginDay !== endDay)
                ) {
                    return first;
                } else if (begin < first) {
                    return begin;
                }
                return first + 1 + (begin - last);
            }
        }
        return undefined;
    };

    const time = getFirstVisibleValue(this.getHeaders());
    const date = new MillenniumDateTime(reservation.begin).getMillenniumDate();
    if (date >= this.limits.getStartDate() && date <= this.limits.getEndDate()) {
        return this.gotoDateTime(date, spotlight, time, true, {
            beginTime,
            endTime,
        });
    }
    return this.gotoDateTime(date, false);
};

Calendar.prototype.getFirstVisibleDate = function () {
    const indexes = this.getPeriodHeaders().map(() => 0);
    return this.getVisibleDates(indexes)[0];
};

Calendar.prototype.goToToday = function () {
    let date = MillenniumDate.today();
    const startDate = this.limits.getStartDate();
    const endDate = this.limits.getEndDate();
    let dateVisible = true;
    const typeMap = this.getHeaderTypeMap();
    if (typeMap.date) {
        dateVisible = typeMap.date.isWeekdayVisible(date);
    }

    if (date.isBefore(startDate) || date.isAfter(endDate)) {
        Log.warning(
            Language.get(
                "nc_date_could_not_be_displayed_in_calendar.",
                SimpleDateFormat.format(date, Language.getDateFormat("date_f_yyyy_mm_dd"))
            )
        );
        return this;
    }

    if (dateVisible) {
        return this.gotoDateTime(date);
    }

    const originalDate = date;
    const firstWeekday = new MillenniumWeek(
        // eslint-disable-next-line no-magic-numbers
        date.addDays(3),
        Language.firstDayOfWeek,
        Language.daysInFirstWeek
    ).getStartDate();
    let dayBeforeWeekend = date;
    while (dayBeforeWeekend.isWeekend()) {
        dayBeforeWeekend = dayBeforeWeekend.addDays(-1);
    }

    if (firstWeekday.isBefore(endDate)) {
        date = firstWeekday;
    } else if (dayBeforeWeekend.isAfter(startDate)) {
        date = dayBeforeWeekend;
    }

    Log.warning(
        Language.get(
            "nc_date_could_not_be_displayed_in_calendar.",
            SimpleDateFormat.format(originalDate, Language.getDateFormat("date_f_yyyy_mm_dd"))
        )
    );
    return this.gotoDateTime(date, false);
};

Calendar.prototype.gotoDateTime = function (
    date,
    spotlight = true,
    time = undefined,
    stopIfVisible = true,
    hourRange = undefined
) {
    let visibleDates: any[] = [];
    try {
        visibleDates = this.getVisibleDates();
        if (
            stopIfVisible &&
            _.contains(
                visibleDates.map((dt) => dt.getDayNumber()),
                date.getDayNumber()
            )
        ) {
            return this.immutableSet({
                useSpotlight: spotlight,
                spotlightDate: spotlight ? date : null,
                spotlightTime: spotlight ? hourRange : null,
            });
        }
    } catch (error) {
        // Ignore
    }
    let startDate = date;
    const header = this.getHeader(DateHeader);
    if (header && header.visibleValues >= header.daysPerWeek) {
        const firstDateInWeek = new MillenniumWeek(
            date,
            Language.firstDayOfWeek,
            Language.daysInFirstWeek
        ).getStartDate();
        if (firstDateInWeek >= this.limits.getStartDate()) {
            startDate = firstDateInWeek;
        }
    }

    const adjustHeader = function (hdr) {
        let adjustedHeader = null;
        let foundDate = false;
        let hasDateHeader;

        hdr.getHeaders()
            .reverse()
            .forEach((el) => {
                let firstVisible = el.firstVisibleValue;
                if (el instanceof DateHeader || el instanceof WeekHeader) {
                    hasDateHeader = true;
                    if (el.containsDate(startDate)) {
                        firstVisible = el.getIndexOfDate(startDate);
                        foundDate = true;
                    }
                }

                if (el instanceof TimeHeader && time) {
                    firstVisible = time;
                }

                adjustedHeader = el.immutableSet({
                    firstVisibleValue: firstVisible,
                    subheader: adjustedHeader,
                });
            });
        return { foundDate, header: adjustedHeader, hasDateHeader };
    };

    const xResult = adjustHeader(this.xHeader);
    const yResult = adjustHeader(this.yHeader);
    const foundDate = xResult.foundDate || yResult.foundDate;
    const hasDateHeader = xResult.hasDateHeader || yResult.hasDateHeader;

    if (!foundDate) {
        if (
            hasDateHeader ||
            startDate.isBefore(
                this.limits.getStartDate() || startDate.isAfter(this.limits.getEndDate())
            )
        ) {
            Log.warning(
                Language.get(
                    "nc_date_could_not_be_displayed_in_calendar.",
                    SimpleDateFormat.format(startDate, Language.getDateFormat("date_f_yyyy_mm_dd"))
                )
            );
        } else {
            return this.immutableSet({
                firstVisibleDate: startDate,
                useSpotlight: spotlight,
                spotlightDate: spotlight ? date : null,
                spotlightTime: spotlight ? hourRange : null,
            });
        }
    }

    return this.immutableSet({
        xHeader: xResult.header,
        yHeader: yResult.header,
        useSpotlight: spotlight,
        spotlightDate: spotlight ? date : null,
    });
};

Calendar.prototype.getDaysPerWeek = function () {
    const dateHeader = this.getHeader(DateHeader);
    if (dateHeader) {
        return dateHeader.daysPerWeek;
    }
    return TC.DAYS_PER_WEEK;
};

Calendar.prototype.getAvailableHeaders = function (ignoreHeader, includeIgnoreHeader) {
    let headers = this.getHeaders();
    if (ignoreHeader) {
        headers = headers.filter((header) => header !== ignoreHeader);
    }

    const hasHeaderType = (headerModel) =>
        _.find(headers, (header) => header instanceof headerModel);

    const available: {
        name: string;
        header: any;
        period?: string;
    }[] = [{ name: Language.get("cal_header_kind_object"), header: ObjectHeader }];

    if (!hasHeaderType(TimeHeader)) {
        available.push({
            name: Language.get("cal_header_kind_time"),
            header: TimeHeader,
            period: "periodtime",
        });
    }

    if (!hasHeaderType(DateHeader) && !hasHeaderType(WeekHeader) && !hasHeaderType(WeekdayHeader)) {
        available.push({
            name: Language.get("cal_header_kind_date"),
            header: DateHeader,
            period: "perioddate",
        });
        available.push({
            name: Language.get("cal_header_kind_week"),
            header: WeekHeader,
            period: "periodweek",
        });
        available.push({
            name: Language.get("cal_header_kind_weekday"),
            header: WeekdayHeader,
            period: "periodweekday",
        });
    } else if (!hasHeaderType(DateHeader) && !hasHeaderType(WeekHeader)) {
        available.push({
            name: Language.get("cal_header_kind_week"),
            header: WeekHeader,
            period: "periodweek",
        });
    } else if (!hasHeaderType(DateHeader) && !hasHeaderType(WeekdayHeader)) {
        available.push({
            name: Language.get("cal_header_kind_weekday"),
            header: WeekdayHeader,
            period: "periodweekday",
        });
    }

    if (includeIgnoreHeader === true) {
        return available;
    }
    return available.filter((item) => !(ignoreHeader instanceof item.header));
};

Calendar.prototype.moveHeaderDown = function (isXAxis) {
    const headerProp = isXAxis ? "xHeader" : "yHeader";
    return this.immutableSet((newCalendar) => {
        const newTopHeader = newCalendar[headerProp].subheader;
        // eslint-disable-next-line no-param-reassign
        newCalendar[headerProp].subheader = newTopHeader.subheader;
        newTopHeader.subheader = newCalendar[headerProp];
        // eslint-disable-next-line no-param-reassign
        newCalendar[headerProp] = newTopHeader;
    });
};

Calendar.prototype.setHeader = function (isXAxis, newHeader) {
    const headerProp = isXAxis ? "xHeader" : "yHeader";
    const diff = {};
    diff[headerProp] = newHeader ? newHeader : this[headerProp].subheader;
    if (!newHeader && this[headerProp].isActive) {
        diff[headerProp] = diff[headerProp].setActiveHeader(diff[headerProp]);
    }
    return this.immutableSet(diff);
};

Calendar.prototype.getPeriodHeaders = function () {
    return _.sortBy(
        this.getHeaders().filter((header) => header instanceof PeriodHeader),
        (header) => header.getId()
    );
};

Calendar.prototype.hasPeriodHeaders = function () {
    return this.getPeriodHeaders().length > 0;
};

Calendar.prototype.getPeriodCombinations = function (periodHeadersArg?) {
    const periodHeaders = periodHeadersArg ?? this.getPeriodHeaders();
    if (periodHeaders.length === 0) {
        return [];
    }

    // Create all combinations of period values, i.e. [{ header1: index1, header2: index1 }, { header1: index2, header2: index1 }, etc]
    const periodIndexes = periodHeaders.map((header) => _.range(0, header.visibleValues));
    const periodIds = periodHeaders.map((header) => header.getId());

    return _.combineArrays(periodIndexes).map((indexArray) => _.object(periodIds, indexArray));
};

Calendar.prototype.findOverlappingEntries = function (
    fluffy,
    currentReservationId,
    startTime,
    endTime,
    onlyVisible,
    objects,
    period,
    obstacleTextTypes,
    callback
) {
    const timeHeader = this.getHeader(TimeHeader);

    if (timeHeader && onlyVisible === true) {
        const visibleTimes = timeHeader.getVisibleValues();

        // eslint-disable-next-line no-param-reassign
        startTime = startTime.map((time) => {
            if (time.getMillenniumTime().getTimeNumber() < visibleTimes[0]) {
                return new MillenniumDateTime(
                    time.getMillenniumDate(),
                    new MillenniumTime(visibleTimes[0])
                );
            }
            return time;
        });
        // eslint-disable-next-line no-param-reassign
        endTime = endTime.map((time) => {
            if (time.getMillenniumTime().getTimeNumber() > visibleTimes[visibleTimes.length - 1]) {
                return new MillenniumDateTime(
                    time.getMillenniumDate(),
                    new MillenniumTime(visibleTimes[visibleTimes.length - 1])
                );
            }
            return time;
        });
    }

    const searchData: {
        currentReservationId: number;
        beginTime: any;
        endTime: any;
        fluffy: any;
        showInfoEntries: boolean;
        overlapCount: boolean;
        obstacleTextTypes: any;
        objectList?: any[];
    } = {
        currentReservationId,
        beginTime: startTime,
        endTime,
        fluffy,
        showInfoEntries: false,
        overlapCount: false,
        obstacleTextTypes,
    };

    searchData.objectList = this.getHeaderObjects().filter(
        (item) => _.intersection(item.objects, objects).length > 0
    );
    // eslint-disable-next-line no-param-reassign
    searchData.objectList?.forEach((item) => (item.objects = objects));

    this.findEntries(
        searchData,
        // eslint-disable-next-line no-unused-vars
        (entries, availability, isAbsoluteAvailability) => {
            let result = entries.filter((entry) => entry.overlapCountable);
            if (period) {
                result = result.map((entry) => _.extend(entry, { periods: period }));
            }
            callback(result);
        },
        period
    );
};

const sortEntries = (entries) => {
    entries.sort((a, b) => {
        const pos = (item) => item.position;
        const len = (item) => item.getLength();
        const start = (item) => item.startTimes[0].getMillenniumTime().getTimeNumber();
        const depth = (item) => item.reservationids.length;
        const created = (item) => item.reservationids[0];
        const layer = (item) => item.layer;

        const cmp = (fn, item1, item2) => fn(item1) - fn(item2);
        return (
            cmp(layer, a, b) ||
            cmp(pos, b, a) ||
            cmp(len, b, a) ||
            cmp(start, b, a) ||
            cmp(depth, a, b) ||
            cmp(created, b, a)
        );
    });
    return entries;
};

const createPaddingEntry = (parentEntry, padding, isBeforeParent) => {
    const paddingEntry = Object.assign(new Entry(), parentEntry);
    if (isBeforeParent) {
        paddingEntry.endTimes = paddingEntry.endTimes.map(
            (endTime, index) => paddingEntry.startTimes[index]
        );
        paddingEntry.startTimes = paddingEntry.startTimes.map((beginTime) =>
            beginTime.addSeconds(-padding.padding)
        );
    } else {
        paddingEntry.startTimes = paddingEntry.startTimes.map(
            (beginTime, index) => paddingEntry.endTimes[index]
        );
        paddingEntry.endTimes = paddingEntry.endTimes.map((endTime) =>
            endTime.addSeconds(padding.padding)
        );
    }
    paddingEntry.isPadding = true;
    paddingEntry.padding = isBeforeParent ? parentEntry.padding.before : parentEntry.padding.after;
    return paddingEntry;
};

const addPadding = (entries: TEntry[]) => {
    const paddingEntries: TEntry[] = [];
    entries.forEach((entry) => {
        if (entry.hasPadding()) {
            if (entry.padding.before.padding > 0) {
                paddingEntries.push(createPaddingEntry(entry, entry.padding.before, true));
            }
            if (entry.padding.after.padding > 0) {
                paddingEntries.push(createPaddingEntry(entry, entry.padding.after, false));
            }
        }
    });
    return [...paddingEntries, ...entries];
};

Calendar.prototype.findEntries = function (inSearchData, callback, specificPeriod) {
    if (!inSearchData.fluffy) {
        throw new Error("Finding entries without a McFluffy is not allowed");
    }

    const finish = (entries, availability, isAbsoluteAvailability, summaries) => {
        callback(entries, availability, isAbsoluteAvailability, summaries);
    };

    const searchData = _.clone(inSearchData);
    if (this.typeFilter.length > 0) {
        let fluffy = searchData.fluffy;
        fluffy = fluffy.immutableSet({
            objectItems: fluffy.objectItems.map((item) => {
                if (_.contains(this.typeFilter, item.type.id)) {
                    return item;
                }

                return _.extend({}, item, {
                    object: {
                        id: 0,
                        extid: "",
                        class: "objectid",
                    },
                });
            }),
        });
        searchData.fluffy = fluffy;
    }

    if (this.colorTypes.length > 0) {
        searchData.colorTypes = this.colorTypes;
    }

    const hasTime = this.yHeader.hasTime() || this.xHeader.hasTime();
    const limits = this.limits;
    if (!this.hasPeriodHeaders() || specificPeriod) {
        // Specific period is set when finding overlapping entries, and in that case
        // begin and end times must be set in search data.
        searchData.beginTime = searchData.beginTime || this.getStartDateTime();
        searchData.endTime = searchData.endTime || this.getEndDateTime();

        if (searchData.beginTime.length === 0 || searchData.endTime.length === 0) {
            finish([], [], false, []);
            return;
        }

        API.findEntries(
            this.getSearchData(searchData, specificPeriod),
            (entries, availability, isAbsoluteAvailability, summaries) => {
                if (hasTime) {
                    finish(
                        sortEntries(addPadding(entries)),
                        availability,
                        isAbsoluteAvailability,
                        summaries
                    );
                    return;
                }

                entries.forEach((entry) => {
                    const startSeconds = entry.startTimes[0].getMts() % TC.SECONDS_PER_DAY;
                    const endSeconds = ((entry.endTimes[0].getMts() - 1) % TC.SECONDS_PER_DAY) + 1;
                    if (
                        limits.startTime !== startSeconds ||
                        endSeconds !== limits.startTime + limits.timeCount
                    ) {
                        // eslint-disable-next-line no-param-reassign
                        entry.isPartial = true;
                    }
                });

                finish(
                    sortEntries(addPadding(entries)),
                    availability,
                    isAbsoluteAvailability,
                    summaries
                );
            }
        );
        return;
    }

    const periodHeaders = this.getPeriodHeaders();
    const combos = this.getPeriodCombinations();
    const timePeriodHeader = _.find(periodHeaders, (header) => header instanceof TimePeriodHeader);

    // Calculate begin and end times for query
    let beginTimes = [];
    let endTimes = [];
    combos.forEach((combo) => {
        const indexes = _.values(combo);
        try {
            beginTimes = beginTimes.concat(this.getStartDateTime(indexes, false));
            endTimes = endTimes.concat(this.getEndDateTime(indexes, false));
        } catch (e) {
            // There are cases where a date cannot be created (e.g. a custom date period can have a column without a date)
            // If so, simply move on to the next combo
            return;
        }
    });

    // Get cluster period combinations
    const clusterPeriodHeaders = this.getHeaders().filter(
        (header) => header instanceof PeriodHeader && header.getMaxClusterDepth() > 1
    );
    const maxDepth = this.getMaxClusterDepth();
    if (timePeriodHeader && maxDepth > 1) {
        clusterPeriodHeaders.push(timePeriodHeader);
    }
    const clusterCombos = this.getPeriodCombinations(clusterPeriodHeaders);
    const clusterValues = clusterCombos.map(
        (clusterCombo) => this.getSearchData(searchData, clusterCombo).fluffyProps.clusterValues
    );

    // Create base query
    const query = this.getSearchData(searchData, combos[0]);

    // Set cluster values
    query.fluffyProps.clusterValues = clusterValues;
    if (clusterValues.length === 0) {
        query.fluffyProps.clusterKind = 0;
    }

    query.fluffyProps.showConflictingObjects = true;

    // Get non-cluster periods
    const nonClusterPeriodHeaders = this.getHeaders().filter(
        (header) => header instanceof PeriodHeader && header.getMaxClusterDepth() <= 1
    );
    query.fluffyProps.periodKinds = nonClusterPeriodHeaders.map((ph) => ph.getKind());
    query.fluffyProps.periodBegins = nonClusterPeriodHeaders.map((ph) => {
        if (ph instanceof TimePeriodHeader) {
            return _.flatten(
                ph.getValues().map((value) => value.map((val) => val.start.getTimeNumber()))
            );
        }
        return ph.periodsToJSON();
    });
    query.fluffyProps.periodEnds = nonClusterPeriodHeaders.map((ph) => {
        if (ph instanceof TimePeriodHeader) {
            return _.flatten(
                ph.getValues().map((value) => value.map((val) => val.end.getTimeNumber()))
            );
        }
        return [];
    }); // Empty lists for everything but time which has the end time

    query.beginTime = beginTimes;
    query.endTime = endTimes;

    if (query.beginTime.length === 0 || query.endTime.length === 0) {
        finish([], [], false, []);
        return;
    }

    API.findEntries(query, (entries, availability, isAbsoluteAvailability, summaries) => {
        entries.forEach((entry) => {
            // Each entry has a cluster ID, which starts on 1 if there are clusters
            let entryPeriods = {};
            if (clusterValues.length > 0) {
                const index = entry.clusterId ? entry.clusterId - 1 : 0;
                entryPeriods = _.extend(entryPeriods, clusterCombos[index]);
            }
            if (nonClusterPeriodHeaders.length > 0) {
                const periodIds = entry.periodIds || [1];
                // eslint-disable-next-line no-param-reassign
                entry.periods = entry.periods || {};
                periodIds.forEach((slot, periodIndex) => {
                    const header = nonClusterPeriodHeaders[periodIndex];
                    const headerId = header.getId();
                    entryPeriods = _.extend(entryPeriods, {
                        [headerId]: slot - 1,
                    });
                });
            }
            // eslint-disable-next-line no-param-reassign
            entry.periods = entryPeriods;
            const startSeconds = entry.startTimes[0].getMts() % TC.SECONDS_PER_DAY;
            const endSeconds = ((entry.endTimes[0].getMts() - 1) % TC.SECONDS_PER_DAY) + 1;
            if (!hasTime && !timePeriodHeader) {
                // eslint-disable-next-line no-param-reassign
                entry.isPartial =
                    limits.startTime !== startSeconds ||
                    endSeconds !== limits.startTime + limits.timeCount;
            }
        });

        availability.forEach((availabilityEntry) => {
            let availabilityPeriods = {};
            if (clusterValues.length > 0) {
                const index = availabilityEntry.clusterId ? availabilityEntry.clusterId - 1 : 0;
                availabilityPeriods = _.extend(availabilityPeriods, clusterCombos[index]);
            }
            if (nonClusterPeriodHeaders.length > 0) {
                const periodIds = availabilityEntry.periodIds || [1];
                // eslint-disable-next-line no-param-reassign
                availabilityEntry.periods = availabilityEntry.periods || {};
                periodIds.forEach((slot, periodIndex) => {
                    const header = nonClusterPeriodHeaders[periodIndex];
                    const headerId = header.getId();
                    availabilityPeriods = _.extend(availabilityPeriods, {
                        [headerId]: slot - 1,
                    });
                });
            }
            // eslint-disable-next-line no-param-reassign
            availabilityEntry.periods = availabilityPeriods;
            const startSeconds = availabilityEntry.startTimes[0].getMts() % TC.SECONDS_PER_DAY;
            const endSeconds =
                ((availabilityEntry.endTimes[0].getMts() - 1) % TC.SECONDS_PER_DAY) + 1;
            if (!hasTime && !timePeriodHeader) {
                // eslint-disable-next-line no-param-reassign
                availabilityEntry.isPartial =
                    limits.startTime !== startSeconds ||
                    endSeconds !== limits.startTime + limits.timeCount;
            }
            //availabilityEntry.periods = combos[availabilityEntry.clusterId];
        });

        finish(sortEntries(addPadding(entries)), availability, isAbsoluteAvailability, summaries);
    });
};

Calendar.prototype.getSearchData = function (data, periodCombination) {
    const clusterKind = data.clusterKind || this.getClusterKind(periodCombination);
    const toIntegerRepresentation = (values) => {
        if (values[0] instanceof MillenniumWeek) {
            return values.map((week) => parseInt(week.week(), 10));
        }
        if (values[0] instanceof MillenniumWeekday) {
            return values.map((weekday) => weekday.daysFromWeekday(Language.firstDayOfWeek));
        }
        if (values[0] instanceof MillenniumDate) {
            return values.map((date) => date.getDayNumber());
        }
        return [];
    };

    return {
        beginTime: data.beginTime,
        endTime: data.endTime,
        objectLists: data.objectList || this.getHeaderObjects(),
        fluffy: data.fluffy.toJson(),
        currentReservationId: data.currentReservationId,
        fluffyProps: {
            showEntryText: true,
            showInfoEntries: !this.hideInfoReservations,
            showObstacles: !this.hideObstacles,
            showAbstract: !this.hideAbstractObstacles,
            includeMembers: true,
            clusterKind,
            clusterValues:
                clusterKind > 0
                    ? toIntegerRepresentation(this.getClusterValues(periodCombination))
                    : [],
            includeAvailability: true, // Possibility for future option expansion, a read-only view might want to skip availability
            groupObstacles: false,
            clusterGroupObstacles: clusterKind !== ClusterKind.NONE,
            overlapCount: data.overlapCount !== undefined ? data.overlapCount : true,
            selectedHeaderObjects: data.selectedHeaderObjects || [],
            sumTypes: this.sumTypes || [],
            sumIncludeMembers: this.sumIncludeMembers || [],
            sumWeek: this.sumWeek || 0,
            sumIsFrameTime: this.sumIsFrameTime || false,
            sumFrameTemplateGroup: this.sumFrameTemplateGroup || 0,
            showConflictingObjects: true,
        },
        obstacleTextTypes: data.obstacleTextTypes || [],
        colorTypes: data.colorTypes || [],
    };
};

Calendar.prototype.selectionContainsGroups = function (selectionGroupArg?: any[]) {
    const selectionGroup = selectionGroupArg ?? this.selectionGroup;
    if (selectionGroup.length === 0) {
        return false;
    }
    if (_.every(selectionGroup, (group) => group.groups.length === 0)) {
        return false;
    }
    return true;
};

Calendar.prototype.isLegalGroup = function (useNewReservationGroups = false, selectionGroupArg?) {
    const selectionGroup = selectionGroupArg ?? this.selectionGroup;
    return isLegalGroup(useNewReservationGroups, selectionGroup);
};

Calendar.prototype.setTemplateKind = function (newTemplateKind) {
    if (newTemplateKind === this.templateKind.number) {
        return this;
    }
    const newKind = TemplateKind.get(newTemplateKind);

    let isPrivate = false;
    if (!TemplateKind.equals(newKind, TemplateKind.RESERVATION)) {
        isPrivate = true;
    }
    return this.immutableSet({
        privateSelected: isPrivate,
        templateKind: newKind,
        selection: new Selection(),
    });
};

Calendar.prototype.getSettings = function (publicSelection) {
    const self = this;
    const settings = new Settings([
        {
            id: "readOnly",
            label: Language.get("cal_header_topleft_read_only"),
            type: "boolean",
            get() {
                return self.readOnly;
            },
            set(val) {
                if (val === self.readOnly) {
                    return self;
                }

                let typeFilter = self.typeFilter;
                if (self.typeFilter.length > 0) {
                    typeFilter = [];
                }

                return self.immutableSet({
                    readOnly: val,
                    typeFilter,
                });
            },
        },
        {
            id: "hideInfoReservations",
            label: Language.get("cal_res_side_view_hide_info_reservations"),
            type: "boolean",
            get() {
                return self.hideInfoReservations;
            },
            set(val) {
                return self.immutableSet({ hideInfoReservations: val });
            },
        },
        {
            id: "isPrivate",
            label: Language.get("cal_header_topleft_ignore_selected"),
            type: "boolean",
            isDisabled() {
                return !TemplateKind.equals(TemplateKind.RESERVATION, self.templateKind);
            },
            get() {
                return self.privateSelected;
            },
            set(isPrivate) {
                if (isPrivate === self.privateSelected) {
                    return self;
                }

                const selection = isPrivate ? publicSelection.mutableCopy() : null;
                return self.immutableSet({
                    privateSelected: isPrivate,
                    selection,
                });
            },
        },
        {
            id: "paddingActive",
            label: Language.get("nc_calendar_hide_padding"),
            type: "boolean",
            get() {
                return !self.isPaddingActive;
            },
            set(val) {
                if (val !== self.isPaddingActive) {
                    return self;
                }

                return self.immutableSet({
                    isPaddingActive: !val,
                });
            },
        },
        {
            id: "hideObstacles",
            label: Language.get("cal_res_side_view_hide_obstacles"),
            type: "boolean",
            get() {
                return self.hideObstacles;
            },
            set(val) {
                if (val === self.hideObstacles) {
                    return self;
                }

                return self.immutableSet({
                    hideObstacles: val,
                });
            },
        },
        {
            id: "hideAbstractObstacles",
            label: Language.get("cal_res_side_view_hide_abstract"),
            type: "boolean",
            get() {
                return self.hideAbstractObstacles;
            },
            set(val) {
                if (val === self.hideAbstractObstacles) {
                    return self;
                }

                return self.immutableSet({
                    hideAbstractObstacles: val,
                });
            },
        },
        {
            id: "scrollDayMacro",
            label: Language.get("nc_follows_date"),
            details: Language.get("nc_follows_date_details"),
            type: "boolean",
            get() {
                return self.scrollDayMacro;
            },
            set(val) {
                return self.immutableSet({
                    scrollDayMacro: val,
                });
            },
        },
        {
            id: "function",
            label: Language.get("cal_func_res_function"),
            type: "array",
            limit: 1,
            get() {
                return [
                    {
                        label: Language.get("cal_func_res_reservation"),
                        value: TemplateKind.RESERVATION.number,
                        selected: TemplateKind.equals(TemplateKind.RESERVATION, self.templateKind),
                    },
                    {
                        label: Language.get("cal_func_res_availability"),
                        value: TemplateKind.AVAILABILITY.number,
                        selected: TemplateKind.equals(TemplateKind.AVAILABILITY, self.templateKind),
                    },
                    {
                        label: Language.get("cal_func_res_info_reservation"),
                        value: TemplateKind.INFO_RESERVATION.number,
                        selected: TemplateKind.equals(
                            TemplateKind.INFO_RESERVATION,
                            self.templateKind
                        ),
                    },
                ];
            },
            set(val) {
                if (val === self.templateKind.number) {
                    return self;
                }
                const newKind = TemplateKind.get(val);

                let isPrivate = false;
                if (!TemplateKind.equals(newKind, TemplateKind.RESERVATION)) {
                    isPrivate = true;
                }
                return self.immutableSet({
                    privateSelected: isPrivate,
                    templateKind: newKind,
                    selection: new Selection(),
                });
            },
        },
        {
            id: "isStartDayAbsolute",
            label: Language.get("nc_cal_res_side_view_limit_type"),
            type: "array",
            limit: 1,
            get() {
                return [
                    {
                        label: Language.get("nc_cal_res_side_view_relative_limits"),
                        value: false,
                        selected: self.limits.isStartDayAbsolute === false,
                    },
                    {
                        label: Language.get("nc_cal_res_side_view_absolute_limits"),
                        value: true,
                        selected: self.limits.isStartDayAbsolute === true && !self.isTimePeriod,
                    },
                    {
                        label: Language.get("nc_header_type_time_period"),
                        value: null,
                        selected:
                            self.periodId !== 0 ||
                            (self.limits.isStartDayAbsolute === true && self.isTimePeriod),
                    },
                ];
            },
            set(val) {
                let isTimePeriod = false;
                if (val === null) {
                    isTimePeriod = true;
                    // eslint-disable-next-line no-param-reassign
                    val = true;
                }

                return self
                    .setLimits(self.limits.setIsStartDayAbsolute(val))
                    .setIsTimePeriod(isTimePeriod)
                    .setPeriodId(0);
            },
        },
    ]);

    if (self.limits.isStartDayAbsolute && !self.isTimePeriod) {
        settings.items.push({
            id: "startDate",
            label: Language.get("cal_res_side_view_start_day"),
            type: "date",
            get() {
                return self.limits.getStartDate();
            },
            set(val) {
                if (self.limits.getStartDate().equals(val)) {
                    return self;
                }

                if (val.getDayNumber() >= self.limits.getEndDate().getDayNumber()) {
                    // eslint-disable-next-line no-param-reassign
                    val.dayNumber = self.limits.getEndDate().getDayNumber() - TC.DAYS_PER_WEEK;
                }

                return self.setLimits(self.limits.setStartDayFromDate(val));
            },
        });
        settings.items.push({
            id: "endDate",
            label: Language.get("cal_res_side_view_end_day"),
            type: "date",
            get() {
                return self.limits.getEndDate();
            },
            set(val) {
                if (self.limits.getEndDate().equals(val)) {
                    return self;
                }
                if (val.getDayNumber() <= self.limits.getStartDate().getDayNumber()) {
                    // eslint-disable-next-line no-param-reassign
                    val.dayNumber = self.limits.getStartDate().getDayNumber() + TC.DAYS_PER_WEEK;
                }

                return self.setLimits(self.limits.setDayCountFromDate(val));
            },
        });
    } else if (self.limits.isStartDayAbsolute === true && self.isTimePeriod) {
        settings.items.push({
            id: "period",
            label: Language.get("nc_header_type_time_period"),
            type: "periodtype",
            limit: 1,
            get() {
                return self.periodId; // Map periods, setting selected if start and end match what's set in the calendar
            },
            set(val) {
                if (!val.start_date) {
                    // The user has selected no period
                    return self.setPeriodId(val.id);
                }
                const newLimits = self.limits
                    .setIsStartDayAbsolute(true)
                    .setStartDay(val.start_date);
                return self
                    .setLimits(
                        newLimits.setDayCountFromDate(new MillenniumDate(val.end_date), true)
                    )
                    .setPeriodId(val.id);
            },
        });
    } else {
        settings.items.push({
            id: "startDate",
            label: Language.get("cal_res_side_view_start_day"),
            type: "dateoffset",
            get() {
                return self.limits.startDay;
            },
            set(val) {
                if (self.limits.startDay === val) {
                    return self;
                }

                return self.setLimits(self.limits.setStartDay(val));
            },
        });
        settings.items.push({
            id: "endDate",
            label: Language.get("cal_res_side_view_end_day"),
            type: "dateoffset",
            get() {
                return self.limits.dayCount + self.limits.startDay;
            },
            set(val) {
                if (self.limits.dayCount === val) {
                    return self;
                }

                const newLimits = new Limits(self.limits);
                newLimits.dayCount = val - self.limits.startDay;
                return self.setLimits(newLimits);
            },
        });
    }

    settings.items.push({
        id: "timeRange",
        label: Language.get("cal_res_side_reservation_time"),
        type: "timerange",
        get() {
            return {
                start: self.limits.getStartTime(),
                end: self.limits.getEndTime(),
            };
        },
        set(val) {
            if (
                self.limits.getStartTime().equals(val.start) &&
                self.limits.getEndTime().equals(val.end)
            ) {
                return self;
            }

            let limits = self.limits.setTimeCountFromTime(val.end);
            limits = limits.setStartTimeFromTime(val.start);
            return self.setLimits(limits);
        },
    });

    settings.items.push({
        id: "typeFilter",
        label: Language.get("nc_cal_res_side_view_filter_type"),
        type: "type",
        get() {
            return self.typeFilter;
        },
        set(typeList) {
            if (_.isEqual(typeList, self.typeFilter)) {
                return self;
            }

            let readOnly = self.readOnly;
            if ((!readOnly && typeList.length > 0) || (readOnly && typeList.length === 0)) {
                readOnly = !readOnly;
            }

            return self.immutableSet({
                typeFilter: typeList,
                readOnly,
            });
        },
    });

    settings.items.push({
        id: "colorTypes",
        label: Language.get("nc_cal_res_side_color_type"),
        type: "colortype",
        get() {
            return self.colorTypes;
        },
        set(typeList) {
            const list = typeList === 0 ? [] : _.asArray(typeList);
            if (_.isEqual(list, self.colorTypes)) {
                return self;
            }

            return self.immutableSet({
                colorTypes: list,
            });
        },
    });

    return settings;
};

Calendar.prototype.getMacroFunctions = function () {
    const macros: any[] = [].concat(
        this.macroFunctions.filter(
            (macro) => macro.id !== MACRO_IDS.SET_DAY && macro.id !== MACRO_IDS.SCROLL_DAY
        )
    );
    if (this.setDayMacro === true) {
        macros.push({ id: MACRO_IDS.SET_DAY, name: "setDay" });
    }
    if (this.scrollDayMacro === true) {
        macros.push({ id: MACRO_IDS.SCROLL_DAY, name: "scrollDay" });
    }
    return macros;
};

Calendar.prototype.toJSON = function () {
    return {
        headersX: this.xHeader.getHeaders().map((header) => header.toJSON()),
        headersY: this.yHeader.getHeaders().map((header) => header.toJSON()),
        limits: this.limits,
        availabilityInForeground: this.availabilityInForeground,
        hideInfoReservations: this.hideInfoReservations,
        hideAbstractObstacles: this.hideAbstractObstacles,
        hideObstacles: this.hideObstacles,
        privateSelectedList: this.privateSelected,
        readOnly: this.readOnly,
        typeFilter: this.typeFilter,
        typeColor: this.colorTypes,
        reservationMode: 0,
        function: this.getMacroFunctions(),
        templateKind: this.templateKind.mode,
        periodId: this.periodId,
        hidePadding: !this.isPaddingActive,
    };
};

Calendar.shouldLoadEntries = (calendar1, calendar2) => {
    const relevantProperties = [
        "hideInfoReservations",
        "privateSelected",
        "selection",
        "templateKind",
        "mode",
        "ignoreBackground",
        "hideObstacles",
        "hideAbstractObstacles",
        "availabilityInForeground",
        "limits",
        "xHeader",
        "yHeader",
        "firstVisibleDate",
        "typeFilter",
        "colorTypes",
        "sumTypes",
        "sumWeek",
        "sumIncludeMembers",
        "sumIsFrameTime",
        "sumFrameTemplateGroup",
    ];

    if (calendar1 === calendar2) {
        return false;
    }

    if (calendar1 && calendar2) {
        return _.some(relevantProperties, (prop) => calendar1[prop] !== calendar2[prop]);
    }

    return true;
};

Calendar.create = function (limits) {
    // Only invoked from Section when splitting a calendar or setting a section to be a calendar.

    const defaultLimits = limits || Limits.getDefault();

    let dateHeader = new DateHeader();
    dateHeader.visibleValues = 14;
    dateHeader.isActive = true;
    dateHeader.showInfo = true;
    dateHeader = dateHeader.freeze();
    dateHeader = dateHeader.setLimits(defaultLimits);
    const week = new MillenniumWeek(
        MillenniumDate.today(),
        Language.firstDayOfWeek,
        Language.daysInFirstWeek
    );
    const firstDateInWeek = week.getStartOfWeek().getMillenniumDate();
    dateHeader = dateHeader.immutableSet({
        firstVisibleValue: dateHeader.getIndexOfDate(firstDateInWeek, false),
    });

    let timeHeader = new TimeHeader();
    timeHeader.isActive = true;
    timeHeader = timeHeader.freeze();
    timeHeader = timeHeader.setLimits(defaultLimits);
    return new Calendar(dateHeader, timeHeader, defaultLimits);
};

module.exports = Calendar;
