/*
 * This file mainly exists for the purpose of making current "prototype based" class CommonJs module exports to work with typescript module imports.
 * It should be considered as a workaround until we manage to convert current prototype based model "classes" to ES6 classes (if that will happen).
 *
 * Some things, like the use of "instanceof" will not work if the model file is imported directly withpout the workaround below.
 */

// Header
import { Header as TSHeader } from "./Header";
export const Header: typeof TSHeader = require("./Header");

// WeekdayHeader
import { WeekdayHeader as TSWeekdayHeader } from "./WeekdayHeader";
export const WeekdayHeader: typeof TSWeekdayHeader = require("./WeekdayHeader");

// PeriodHeader
import { PeriodHeader as TSPeriodHeader } from "./PeriodHeader";
export const PeriodHeader: typeof TSPeriodHeader = require("./PeriodHeader");

// Limits
import { Limits as TSLimits } from "./Limits";
export const Limits: typeof TSLimits = require("./Limits");

// Calendar
import { Calendar as TSCalendar } from "./Calendar";
export const Calendar: typeof TSCalendar = require("./Calendar");
