import { useEffect, useRef, useState } from "react";
import { TConflictingObjects } from "../types/TEntry";

const React = require("react");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");

const TOOLTIP_LEFT_OFFSET = 265;
const TOOLTIP_RIGHT_OFFSET = -10;

type TooltipRowProps = {
    id: number;
    name?: string;
    typeName?: string;
    buttonActions: {
        info: (id: number) => void;
    };
};
const TooltipRow = ({ id, name, typeName, buttonActions }: TooltipRowProps) => (
    <tr key={id} className="tooltipRow">
        <td className="wrapText" title={name} style={{ paddingLeft: "5px" }}>
            {name} {typeName ? `(${typeName})` : ""}
        </td>
        <td className="tooltipRowActionButtons">
            <button className="showInfoButton" onClick={() => buttonActions.info(id)} />
        </td>
    </tr>
);

type ReservationConflictInfoTooltipProps = {
    conflictingObjects: TConflictingObjects;
    showObjectInfo: (objectId: number) => void;
    isVisible: boolean;
    onClose?: () => void;
    reservationIds: number[];
    mousePosition: {
        x: number;
        y: number;
        offset: { top: number; left: number };
    };
    tooltipLeft: boolean;
};

const ReservationConflictInfoTooltip = (props: ReservationConflictInfoTooltipProps) => {
    const [objectNamesMap, setObjectNamesMap] = useState<Map<number, string>>();
    const [objectTypesMap, setObjectTypesMap] =
        useState<Map<number, { id: number; extid: string; class: string }>>();
    const [typeNamesMap, setTypeNamesMap] = useState<Map<string, string>>();
    const tooltipRef = useRef<HTMLInputElement>(null);

    const [objects, setObjects] = useState<number[]>();
    const [members, setMembers] = useState<(number | undefined)[]>();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        fetchData(props.conflictingObjects);
        processObjectAndMembers(props.conflictingObjects);
    }, []);

    const processObjectAndMembers = (conflictingObjects: TConflictingObjects) => {
        const objects = conflictingObjects.map((obj) => obj.objectId);
        const members = conflictingObjects
            .flatMap((obj) => obj.members?.map((memberObj) => memberObj.objectId))
            .filter((m) => m !== undefined);

        setObjects(objects);
        setMembers(members);
    };

    const showObjectInfo = (objectId: number) => {
        props.showObjectInfo(objectId);
    };

    const fetchAndSetTypeNames = (typeNamesMap: Map<string, string>): Promise<void> =>
        new Promise((res) => {
            API.findTypes((types) => {
                types.forEach((t) => {
                    typeNamesMap.set(t.extid, t.name);
                });
                res();
            });
        });

    const fetchAndSetObjectNamesAndTypes = (
        objectNamesMap: Map<number, string>,
        objectTypesMap: Map<number, { id: number; extid: string; class: string }>,
        objectIdsFilter: number[]
    ): Promise<void> =>
        new Promise((res) => {
            // Fetch more reservation data, which includes 'type id' that we need.
            API.exportReservations(props.reservationIds, false, (reservations) => {
                if (!reservations.length) return;

                const { objects: objectsOnReservation } = reservations[0];

                const objectNameRequestData: { id: number; type: number }[] = [];
                objectsOnReservation
                    .filter((o) => objectIdsFilter.includes(o.id))
                    .forEach((o) => {
                        // Set object types to map.
                        objectTypesMap.set(o.id, o.type);
                        // Construct request data to use later to get object names.
                        objectNameRequestData.push({ id: o.id, type: o.type.id });
                    });

                // Fetch object names
                API.getObjectNames(objectNameRequestData, true, (nameResult) => {
                    nameResult.forEach((namedObject) => {
                        const name = namedObject.fields
                            .map((field) => (field.values ? field.values.join(", ") : ""))
                            .join(", ");
                        // Set object names to map.
                        objectNamesMap.set(namedObject.id, name);
                    });
                    res();
                });
            });
        });

    const recursiveFlattenIds = (objArray?: TConflictingObjects): number[] => {
        const ids = objArray?.flatMap(({ objectId, members }) => {
            const nestedResult = recursiveFlattenIds(members);
            return [objectId, ...nestedResult];
        });

        return ids ?? [];
    };

    const fetchData = (conflictingObjects: TConflictingObjects) => {
        const conflictingObjectIds = recursiveFlattenIds(conflictingObjects);

        if (conflictingObjectIds?.length) {
            const objectNamesMap = new Map<number, string>();
            const objectTypesMap = new Map<number, { id: number; extid: string; class: string }>();
            const typeNamesMap = new Map<string, string>();

            // Get object and type names from concurrent API requests.
            Promise.all([
                fetchAndSetTypeNames(typeNamesMap),
                fetchAndSetObjectNamesAndTypes(
                    objectNamesMap,
                    objectTypesMap,
                    conflictingObjectIds
                ),
            ])
                .then(() => {
                    // Success, set data maps in state.
                    setTypeNamesMap(typeNamesMap);
                    setObjectNamesMap(objectNamesMap);
                    setObjectTypesMap(objectTypesMap);
                    setIsLoaded(true);
                })
                .catch((e) => {
                    console.error(
                        "Could not resolve object and type names in Reservation conflict info tooltip.",
                        e
                    );
                });
        }
    };

    if (!props.conflictingObjects || !isLoaded || !props.isVisible) return null;

    let tooltipStyle: React.CSSProperties | null = null;
    if (props.mousePosition) {
        const leftOffset = props.tooltipLeft ? TOOLTIP_LEFT_OFFSET : TOOLTIP_RIGHT_OFFSET;
        const top = props.mousePosition.y - props.mousePosition.offset.top;
        let left = props.mousePosition.x - leftOffset - props.mousePosition.offset.left;
        if (props.tooltipLeft) {
            if (left < 0) {
                left -= left;
            }
        }
        tooltipStyle = { top, left };
    }
    if (!tooltipStyle) {
        return null;
    }

    const classes = {
        entryTooltip: true,
        withButtons: true,
    };

    return (
        <div ref={tooltipRef} className={_.classSet(classes)} style={tooltipStyle}>
            <span>
                <table>
                    <tbody>
                        <tr>
                            <th>{objects?.length} Object(s) in conflict:</th>
                        </tr>
                        {objects?.map((id) => {
                            const extid = objectTypesMap?.get(id)?.extid;
                            return (
                                <TooltipRow
                                    key={id}
                                    id={id}
                                    name={objectNamesMap!.get(id)}
                                    typeName={typeNamesMap!.get(extid!)}
                                    buttonActions={{ info: showObjectInfo }}
                                />
                            );
                        })}
                        {members?.length ? (
                            <>
                                <tr>
                                    <th>{members.length} Member(s) in conflict:</th>
                                </tr>
                                {members.map((id) => {
                                    if (id === undefined) return <></>;
                                    const extid = objectTypesMap?.get(id)?.extid;
                                    return (
                                        <TooltipRow
                                            key={id}
                                            id={id}
                                            name={objectNamesMap!.get(id)}
                                            typeName={typeNamesMap!.get(extid!)}
                                            buttonActions={{ info: showObjectInfo }}
                                        />
                                    );
                                })}
                            </>
                        ) : null}
                    </tbody>
                </table>
            </span>
            {props.onClose ? (
                <div className="tooltipButtons">
                    <button className="closeButton" onClick={props.onClose} />
                </div>
            ) : null}
        </div>
    );
};

export default ReservationConflictInfoTooltip;
