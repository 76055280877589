const React = require("react");
const createReactClass = require("create-react-class");
const PropTypes = require("prop-types");
const ContextMenu = require("../lib/ContextMenu");
const GenericList = require("./GenericList");
import { MillenniumDateTime, SimpleDateFormat } from "millennium-time";
const _ = require("underscore");
const TimeEdit = require("../lib/TimeEdit");
const API = require("../lib/TimeEditAPI");
const RC = require("../lib/ReservationConstants");
const TC = require("../lib/TimeConstants");
const ReservationStatus = require("../lib/ReservationStatus");
const Language = require("../lib/Language");
const Size = require("../models/Header").Label;
const TemplateKind = require("../models/TemplateKind");
const Selection = require("../models/Selection");
const TimeEditAPI = require("../lib/TimeEditAPI");
const Experiment = require("../models/Experiment");
const OBMC = require("../lib/ReservationConstants").OBJECT_MASS_CHANGE;
const Reservation = require("../models/Reservation");
const Macros = require("../models/Macros");
const Log = require("../lib/Log");
const LayerComponent = require("../lib/LayerComponent");
const EmailDialog = require("./EmailDialog");
const Popover = require("./Popover");
import {
    TYPE_COLUMN_OFFSET,
    FIELD_COLUMN_OFFSET,
    INFO_COLLISION,
    RESERVATION_SORT_ORDERS,
} from "../lib/ColumnConstants";
import { EXAM_MODE } from "../lib/ExamConstants";

const MAX_RESERVATIONS_MODIFY = 500;
const LIMIT_RESERVATION_DELETE = 200;

const LABEL_SIZE_CUTOFFS = {
    MINIMUM: 10,
    XS: 30,
    S: 40,
    M: 60,
    L: 110,
};

const SETTINGS_TO_SAVE = [
    "allReservations",
    "beginTime",
    "columnWidths",
    "complete",
    "endTime",
    "includeMembers",
    "incomplete",
    "permission",
    "reservationStatus",
    "reservationVariant",
    "selectedColumns",
    "selectedInfoColumns",
    "templateGroup",
    "templateKind",
    "useTemplateGroup",
    "objectCategories",
    "reservationCategories",
    "ignoreExceptions",
    "reservationGroupsOnly",
    "placeholderType",
];

let ReservationTypeList = createReactClass({
    displayName: "ReservationTypeList",

    getInitialState() {
        return {
            selectedIds: [],
            selectedObjectCheckSum: null,
            hasExamId: false,
            hasExamValue: false,
        };
    },

    contextTypes: {
        user: PropTypes.object,
        fireEvent: PropTypes.func,
        update: PropTypes.func,
        presentModal: PropTypes.func,
        customWeekNames: PropTypes.array,
        examComponentActive: PropTypes.bool,
        createExamRequest: PropTypes.func,
        examIdField: PropTypes.number,
        useNewReservationGroups: PropTypes.bool,
        isLockingEnabled: PropTypes.func,
        envIsNotProduction: PropTypes.func,
    },

    componentDidMount() {
        this._isMounted = true;

        this.props.setLayerContentProvider(this.getLayerContent);
        this.props.setLayerCloseHandler(this.onLayerClose);

        if (
            !TemplateKind.equals(
                this.props.data.templateKind,
                this.props.searchOptions.templateKind
            )
        ) {
            this.updateTemplateKind();
        }
        if (this.props.isActive) {
            this.bindKeyboardShortcuts();
        }
    },

    componentDidUpdate(prevProps) {
        if (!prevProps.isActive && this.props.isActive) {
            this.bindKeyboardShortcuts();
        }
        if (
            !TemplateKind.equals(
                this.props.searchOptions.templateKind,
                prevProps.searchOptions.templateKind
            )
        ) {
            this.updateTemplateKind();
        }
    },

    componentWillUnmount() {
        this._isMounted = false;
        this.unbindKeyboardShortcuts();
    },

    onSelectionChanged(selectedReservations) {
        const newIds = selectedReservations.map((res) => res.id);
        if (_.isEqual(newIds, this.state.selectedIds)) {
            return;
        }
        this.setState({
            selectedIds: newIds,
            selectedObjectCheckSum:
                selectedReservations.length === 1 ? selectedReservations[0].objectCheckSum : null,
        });
        if (this.props.onSelectionChanged) {
            this.props.onSelectionChanged(selectedReservations);
        }
    },

    bindKeyboardShortcuts() {
        // eslint-disable-next-line no-undef
        Mousetrap.unbindWithHelp("mod+i");
        // eslint-disable-next-line no-undef
        Mousetrap.unbindWithHelp("mod+e");
        // eslint-disable-next-line no-undef
        Mousetrap.bindWithHelp(
            "mod+i",
            () => {
                this.viewInfo(this.state.selectedIds);
            },
            undefined,
            Language.get("nc_cal_show_or_hide_reservation_info.")
        );
        // eslint-disable-next-line no-undef
        Mousetrap.bindWithHelp(
            "mod+e",
            () => {
                this.editFields(this.state.selectedIds);
            },
            undefined,
            Language.get("nc_cal_show_or_hide_reservation_info.")
        );
    },

    unbindKeyboardShortcuts() {
        // eslint-disable-next-line no-undef
        Mousetrap.unbindWithHelp("mod+i");
        // eslint-disable-next-line no-undef
        Mousetrap.unbindWithHelp("mod+e");
    },

    getLayerContent() {
        let close = null;
        let content = null;
        if (this.state.showEmailField === true) {
            close = this.mailDialogClosed;
            const onFinished = () => {
                // eslint-disable-next-line no-undef
                /*mixpanel.track("E-mail sent", {
                    "Explicit action": true,
                    "Invoked from calendar": true,
                    "Invoked from list": false,
                    "Number of reservations in message": this.state.emailReservationIds.length,
                });*/
                this.onMailDialogDismissed();
            };
            content = (
                <EmailDialog
                    reservations={this.state.emailReservationIds}
                    onFinished={onFinished}
                    onCancel={this.onMailDialogDismissed}
                />
            );
        }
        return (
            <Popover target="center" style={{ width: Popover.DEFAULT_WIDTH }} onClose={close}>
                {content}
            </Popover>
        );
    },

    onLayerClose() {
        if (this.state.showEmailField) {
            this.onMailDialogDismissed();
            return;
        }

        this.handleCancelEntryDrag();
    },

    onMailDialogDismissed() {
        this.props.hideLayer();
        this.mailDialogClosed();
    },

    mailDialogClosed() {
        if (!this._isMounted) {
            return;
        }

        this.setState({
            showEmailField: false,
            emailReservationIds: [],
        });
    },

    updateTemplateKind() {
        const newData = this.props.data.immutableSet({
            templateKind: this.props.searchOptions.templateKind,
            selection: new Selection(),
        });
        this.context.update(this.props.data, newData);
    },

    getSelection() {
        if (this.props.data.privateSelected) {
            return this.props.data.selection;
        }
        return this.props.selection;
    },

    getActiveFluffy() {
        const selection = this.getSelection();
        if (selection.fluffy) {
            return selection.fluffy;
        }
        return null;
    },

    reportProgress(newProgress) {
        this.setState(newProgress);
    },

    onMassChange(selectedLayerId, reservationIds, move, ignoreFields, callback) {
        if (selectedLayerId === 0) {
            const modeText = move
                ? `(${Language.get("dialog_modify")})`
                : `(${Language.get("cal_res_below_copy")})`;
            const name = this.props.getLayerName(`Exp (${this.context.user.userId}) ${modeText}`);
            if (!name) {
                return;
            }
            Experiment.begin(
                name,
                reservationIds,
                move,
                ignoreFields,
                this.reportProgress,
                this.context.presentModal,
                this.props.setActiveLayer,
                this.props.onLayerCreated,
                callback
            );
        } else {
            API.addToReservationLayer(
                selectedLayerId,
                reservationIds,
                move,
                [],
                true,
                ignoreFields,
                null,
                callback
            );
        }
    },

    getReturnTypes(columns) {
        const selection = columns.map((column) => column.id);
        const returnTypes = [];
        selection.forEach((selectedId) => {
            let pushId;
            if (selectedId > TYPE_COLUMN_OFFSET && selectedId < FIELD_COLUMN_OFFSET) {
                pushId = selectedId - TYPE_COLUMN_OFFSET;
            }
            // eslint-disable-next-line no-magic-numbers
            if (selectedId === 3) {
                pushId = TimeEdit.rootType;
            }
            if (pushId !== undefined && returnTypes.indexOf(pushId) === -1) {
                returnTypes.push(pushId);
            }
        });
        return returnTypes;
    },

    getReturnFields(columns) {
        const selection = columns.map((column) => column.id);
        const returnFields = [];
        selection.forEach((selectedId) => {
            let pushId;
            if (selectedId > FIELD_COLUMN_OFFSET) {
                pushId = selectedId - FIELD_COLUMN_OFFSET;
            }
            // eslint-disable-next-line no-magic-numbers
            if (selectedId === 4) {
                pushId = TimeEdit.reservationTextField.id;
            }
            if (pushId !== undefined && returnFields.indexOf(pushId) === -1) {
                returnFields.push(pushId);
            }
        });
        return returnFields.map((fieldId) => ({ class: "fieldid", id: fieldId }));
    },

    getStatus() {
        const statuses = [].concat(this.props.searchOptions.reservationStatus);
        if (
            TemplateKind.equals(
                TemplateKind.INFO_RESERVATION,
                this.props.searchOptions.templateKind
            )
        ) {
            statuses.push(RC.STATUS.INFO);
        } else if (
            TemplateKind.equals(TemplateKind.AVAILABILITY, this.props.searchOptions.templateKind)
        ) {
            statuses.push(RC.STATUS.AVAILABILITY);
        } else {
            statuses.push(this.props.defaultStatus);
        }

        if (this.props.searchOptions.complete) {
            statuses.push(RC.STATUS.COMPLETE);
        }
        if (this.props.searchOptions.incomplete) {
            statuses.push(RC.STATUS.INCOMPLETE);
        }
        return statuses;
    },

    getTemplateGroup() {
        if (!this.props.searchOptions.useTemplateGroup) {
            return 0;
        }
        const fluffy = this.getActiveFluffy();
        if (fluffy) {
            return fluffy.templateGroupId || 0;
        }
        return 0;
    },

    findReservations(
        firstIndex,
        searchObjects,
        columns,
        sortOrder,
        sortColumn,
        cb,
        longOperation = false,
        numberOfRows = null // Use default number of rows
    ) {
        let reservationSortOrder = this.getSortOrder(sortOrder, sortColumn);
        let useCreated = false;
        if (
            reservationSortOrder === RESERVATION_SORT_ORDERS.CREATED_ASC ||
            reservationSortOrder === RESERVATION_SORT_ORDERS.CREATED_DESC
        ) {
            useCreated = true;
            // eslint-disable-next-line no-magic-numbers
            reservationSortOrder -= 4;
        }

        const opts = this.props.searchOptions;
        const beginTime = opts.beginTime ? new MillenniumDateTime(opts.beginTime).getMts() : 0;
        const endTime = opts.endTime
            ? new MillenniumDateTime(opts.endTime).getEndOfDay().getMts()
            : 0;
        const layerId = this.props.activeLayer;
        let so = [].concat(searchObjects);
        if (
            opts.objectCategories &&
            _.some(
                Object.keys(opts.objectCategories),
                (key) => opts.objectCategories[key].length > 0
            )
        ) {
            so = so.concat(
                Object.keys(opts.objectCategories).map((key) => ({
                    fieldId: parseInt(key, 10),
                    type: TimeEdit.rootType,
                    categories: opts.objectCategories[key],
                }))
            );
        }

        const searchString = opts.placeholderType
            ? `${opts.searchString} p:"${opts.placeholderType.name}"`
            : opts.searchString;

        const query = {
            searchString,
            beginTime: layerId > 0 ? 0 : beginTime,
            endTime: layerId > 0 ? 0 : endTime,
            layerId,
            searchObjects: so,
            specificFields: Object.keys(opts.reservationCategories || {}).map((key) => ({
                id: parseInt(key, 10),
                categories: opts.reservationCategories[key],
            })),
            reservationIds: [],
            allReservations: opts.allReservations,
            reservationStatus: this.getStatus(),
            includeMembers: opts.includeMembers,
            templateGroup: this.getTemplateGroup(),
            startRow: firstIndex,
            numberOfRows,
            sortOrder: reservationSortOrder,
            returnFields: this.getReturnFields(columns),
            returnTypes: this.getReturnTypes(columns),
            useCreatedInsteadOfModified: useCreated,
            useModifyPermission: opts.permission === RC.PERMISSION.MODIFY,
            ignoreMemberExceptions: opts.ignoreExceptions,
            reservationGroupsOnly: opts.reservationGroupsOnly,
            longOperation,
        };

        const onComplete = (result) => {
            if (!this._isMounted) {
                return;
            }

            if (result.parameters[5] === false) {
                if (
                    result.parameters[6] !== "No reservation tags were found" &&
                    result.parameters[6] !== Language.get("dynamic_reserv_list_no_match")
                ) {
                    Log.warning(result.parameters[6]);
                } else {
                    // eslint-disable-next-line no-console
                    console.log(result.parameters[6]);
                }
            }

            const numFound = result.parameters ? result.parameters[2] : result.length;
            const newData = result.parameters ? result.parameters[0] : result;

            if (this.props.activeLayer !== 0) {
                TimeEditAPI.getReservationCollisions(this.props.activeLayer, (collisions) => {
                    this.setState({ collisions });
                });
            }

            const searchIntervalBegin = result.parameters
                ? new MillenniumDateTime(result.parameters[3].datetime)
                : null;
            const searchIntervalEnd = result.parameters
                ? new MillenniumDateTime(result.parameters[4].datetime)
                : null;
            cb(newData, numFound, searchIntervalBegin, searchIntervalEnd);
        };

        // eslint-disable-next-line no-unused-vars
        const onError = (errorType, faultyMessage, serverResponse) => {
            Log.warning(Language.get("nc_too_many_objects_match_search"));
        };

        if (opts.reservationVariant === RC.VARIANT.RESERVATION) {
            TimeEditAPI.findReservationsList(query, onComplete, onError);
        } else if (opts.reservationVariant === RC.VARIANT.CANCELLED) {
            TimeEditAPI.findReservationsCancelledList(query, onComplete, onError);
        }
    },

    getSortOrder(sortOrder, sortColumn) {
        const ASC = 0;

        // 0 RES_SORT_ID_ASC,
        // 1 RES_SORT_ID_DESC,
        if (sortColumn === Language.get("cal_reservation_list_column_id")) {
            // ID
            return sortOrder;
        }
        // 2 RES_SORT_TIME_ASC,
        // 3 RES_SORT_TIME_DESC,
        if (sortColumn === Language.get("cal_reservation_list_column_date")) {
            // Date
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.TIME_ASC
                : RESERVATION_SORT_ORDERS.TIME_DESC;
        }
        if (this.props.searchOptions.reservationVariant === RC.VARIANT.CANCELLED) {
            if (sortColumn !== Language.get("cal_reservation_list_column_cancelled")) {
                // Cancelled column has ID 5
                return RESERVATION_SORT_ORDERS.TIME_DESC;
            }
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.MODIFIED_ASC
                : RESERVATION_SORT_ORDERS.MODIFIED_DESC;
        }
        // 4 RES_SORT_MODIFIED_ASC,
        // 5 RES_SORT_MODIFIED_DESC
        if (sortColumn === Language.get("cal_reservation_list_column_modified")) {
            // Modified
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.MODIFIED_ASC
                : RESERVATION_SORT_ORDERS.MODIFIED_DESC;
        }
        if (sortColumn === Language.get("nc_tags_title")) {
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.TAGS_ASC
                : RESERVATION_SORT_ORDERS.TAGS_DESC;
        }
        // Made up values for created, transfer to 4 or 5 and set extra parameter useCreatedInsteadOfModified
        if (sortColumn === Language.get("cal_reservation_list_column_created")) {
            // Created
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.CREATED_ASC
                : RESERVATION_SORT_ORDERS.CREATED_DESC;
        }
        return RESERVATION_SORT_ORDERS.TIME_DESC;
    },

    isSortable(sortColumn) {
        return (
            sortColumn === Language.get("cal_reservation_list_column_id") ||
            sortColumn === Language.get("cal_reservation_list_column_date") ||
            sortColumn === Language.get("cal_reservation_list_column_cancelled") ||
            (sortColumn === Language.get("cal_reservation_list_column_modified") &&
                this.props.searchOptions.reservationVariant === RC.VARIANT.CANCELLED) ||
            sortColumn === Language.get("cal_reservation_list_column_modified") ||
            sortColumn === Language.get("cal_reservation_list_column_created") ||
            sortColumn === Language.get("nc_tags_title")
        );
    },

    getLabelSize(size) {
        if (size <= LABEL_SIZE_CUTOFFS.MINIMUM) {
            return null;
        }
        if (size < LABEL_SIZE_CUTOFFS.XS) {
            return Size.XS;
        }
        if (size < LABEL_SIZE_CUTOFFS.S) {
            return Size.S;
        }
        if (size < LABEL_SIZE_CUTOFFS.M) {
            return Size.M;
        }
        if (size < LABEL_SIZE_CUTOFFS.L) {
            return Size.L;
        }
        return Size.XL;
    },

    getTime(time, size, isEnd) {
        switch (this.getLabelSize(size)) {
            case Size.M:
                return SimpleDateFormat.format(
                    time,
                    isEnd
                        ? Language.getDateFormat("date_f_hh_end")
                        : Language.getDateFormat("date_f_hh")
                );
            default:
                return SimpleDateFormat.format(
                    time,
                    isEnd
                        ? Language.getDateFormat("date_f_hh_mm_end")
                        : Language.getDateFormat("date_f_hh_mm")
                );
        }
    },

    getLongDate(date, size) {
        switch (this.getLabelSize(size)) {
            case Size.XS:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_d"));
            case Size.S:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_d"));
            case Size.M:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_m_d"));
            case Size.L:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_ee_m_d"));
            default:
                return SimpleDateFormat.format(
                    date,
                    Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                );
        }
    },

    getCollisionsFor(reservationId) {
        if (!this.state || !this.state.collisions) {
            return false;
        }
        return _.find(this.state.collisions, (cln) => cln.id === reservationId);
    },

    emailReservations(reservationIds) {
        this.setState(
            {
                showEmailField: true,
                emailReservationIds: reservationIds,
            },
            this.props.showLayer
        );
    },

    viewInfo(selectedReservationIds) {
        const user = this.context.user;
        if (user.useInfoPopover) {
            this.context.presentModal(
                <div>{<p>{Language.get("nc_reservationinfo_moved")}</p>}</div>,
                null,
                Language.get("nc_reservationinfo_moved_title")
            );
            this.context.update(user, user.immutableSet({ useInfoPopover: !user.useInfoPopover }));
            TimeEditAPI.setPreferences("useInfoPopover", [!user.useInfoPopover], _.noop);
            this.props.onInfoOpen(selectedReservationIds, true);
        } else {
            this.props.onInfoOpen(selectedReservationIds, true);
        }
    },

    editFields(selectedReservationIds) {
        const user = this.context.user;
        if (user.useInfoPopover) {
            this.context.presentModal(
                <div>{<p>{Language.get("nc_reservationinfo_moved")}</p>}</div>,
                null,
                Language.get("nc_reservationinfo_moved_title")
            );
            this.context.update(user, user.immutableSet({ useInfoPopover: !user.useInfoPopover }));
            TimeEditAPI.setPreferences("useInfoPopover", [!user.useInfoPopover], _.noop);
            this.props.onInfoOpen(selectedReservationIds, true, true);
        } else {
            this.props.onInfoOpen(selectedReservationIds, true, true);
        }
    },

    getReservationMenuItems(
        selectedReservationIds,
        getDataAtIndex,
        callback,
        onMassReplace,
        contextMenuRef,
        currentlyLoadedSelectedData
    ) {
        const items = [];

        if (selectedReservationIds.length === 0) {
            items.push({
                label: Language.get("nc_no_reservations_selected"),
                isDisabled: true,
                emphasize: true,
                action: _.noop,
            });
        }

        items.push([
            {
                label: Language.get("menu_view_info"),
                isDisabled:
                    selectedReservationIds.length > MAX_RESERVATIONS_MODIFY ||
                    selectedReservationIds.length === 0,
                action: () => {
                    this.viewInfo(selectedReservationIds);
                },
                classNames: ["icon", "showInfo"],
                shortcut: TimeEdit.presentShortcut("mod+i"),
            },
        ]);

        const isLocked = false;
        /*const isLocked = currentlyLoadedSelectedData.some(
            (reservation) => reservation.lock && reservation.lock === "soft"
        );*/

        // TODO: Enable lock menu when findReservationsList can provide the information
        /*
        if (this.context.isLockingEnabled()) {
            items.push([
                {
                    label: isLocked ? Language.get("nc_unlock_reservation") : Language.get("nc_lock_reservation"),
                    isDisabled:
                        selectedReservationIds.length > MAX_RESERVATIONS_MODIFY ||
                        selectedReservationIds.length === 0,
                    action: () => {
                        if (isLocked) {
                            // eslint-disable-next-line no-unused-vars
                            API.unlockReservations(selectedReservationIds, (result) => {
                                this.onUpdate(selectedReservationIds);
                            });
                        } else {
                                                      let reason = "";
                          while (reason !== null && reason.trim() === "") {
                              reason = prompt(Language.get("nc_reason_for_lock"));
                          }
                          // User hit cancel
                          if (reason === null) {
                              return;
                          }
                            // eslint-disable-next-line no-unused-vars
                            API.lockReservations(selectedReservationIds, reason, (result) => {
                                this.onUpdate(selectedReservationIds);
                            });
                        }
                    },
                },
            ]);
        }*/

        if (this.props.supportsBatchOperations && !isLocked) {
            items.push(
                this.getBatchMenuItems(
                    selectedReservationIds,
                    getDataAtIndex,
                    callback,
                    onMassReplace,
                    contextMenuRef
                )
            );
        }

        if (this.props.getDataMenuItems && !isLocked) {
            items.push(
                this.props.getDataMenuItems(
                    selectedReservationIds,
                    getDataAtIndex,
                    callback,
                    onMassReplace
                )
            );
        }
        return items;
    },

    getLayerMenuItems(actionFunction) {
        const items = this.props.layers.map((layer) => ({
            key: layer.id,
            label: layer.name,
            action: () => {
                actionFunction(layer.id);
            },
        }));
        return [
            {
                key: 0,
                label: Language.get("nc_new_draft"),
                action: () => {
                    actionFunction(0);
                },
            },
            ...items,
        ];
    },

    getBatchMenuItems(
        selectedReservationIds,
        getDataAtIndex,
        callback,
        onMassChangeObjects,
        contextMenuRef
    ) {
        if (
            this.context.examIdField &&
            this.context.examComponentActive &&
            selectedReservationIds.length === 1
        ) {
            API.exportReservations(selectedReservationIds, false, (result) => {
                let examId = null;
                if (result.length > 0 && result[0]) {
                    examId = _.find(
                        result[0].fields,
                        (field) => field.id === this.context.examIdField
                    );
                }
                this.setState(
                    {
                        hasExamId: examId !== null && examId !== undefined ? true : false,
                        hasExamValue:
                            examId &&
                            examId.values &&
                            examId.values.length > 0 &&
                            examId.values[0] !== "",
                    },
                    () => ContextMenu.update(contextMenuRef)
                );
            });
        }
        const divider = () => ({
            isSeparator: true,
        });
        let experimentModeMenu = null;
        if (
            this.props.activeLayer === 0 &&
            TemplateKind.equals(TemplateKind.RESERVATION, this.props.searchOptions.templateKind)
        ) {
            experimentModeMenu = {
                key: "experimentMode",
                isDisabled: selectedReservationIds.length === 0,
                label: Language.get("nc_drafts_title"),
                submenu: [
                    {
                        key: "experiment.copy",
                        label: Language.get("cal_res_below_copy"),
                        isDisabled: selectedReservationIds.length === 0,
                        submenu: this.getLayerMenuItems((selectedLayerId) => {
                            this.onMassChange(
                                selectedLayerId,
                                selectedReservationIds,
                                false,
                                false,
                                // eslint-disable-next-line no-unused-vars
                                (result) => {
                                    // Log.info("Reservations copied to draft");
                                }
                            );
                        }),
                    },
                    {
                        key: "experiment.copyNoFields",
                        label: Language.get("cal_reservation_action_button_copy_no_field"),
                        isDisabled: selectedReservationIds.length === 0,
                        submenu: this.getLayerMenuItems((selectedLayerId) => {
                            this.onMassChange(
                                selectedLayerId,
                                selectedReservationIds,
                                false,
                                true,
                                // eslint-disable-next-line no-unused-vars
                                (result) => {
                                    // Log.info("Reservations copied to draft without fields");
                                }
                            );
                        }),
                    },
                    {
                        key: "experiment.modify",
                        label: Language.get("dialog_modify"),
                        isDisabled: selectedReservationIds.length === 0,
                        submenu: this.getLayerMenuItems((selectedLayerId) => {
                            this.onMassChange(
                                selectedLayerId,
                                selectedReservationIds,
                                true,
                                false,
                                // eslint-disable-next-line no-unused-vars
                                (result) => {
                                    // Log.info("Reservations added to draft for modification");
                                }
                            );
                        }),
                    },
                ],
            };
        }
        const statusItem = this.props.allStatus
            ? {
                  key: "entry.status",
                  label: Language.get("nc_mass_change_status"),
                  isDisabled: selectedReservationIds.length === 0,
                  submenu: this.props.allStatus.map((statusId) => {
                      const status = ReservationStatus.statusForId(statusId);
                      return {
                          key: `entry.status.${status.id}`,
                          label: status.getStatusName(),
                          tooltip: _.contains(this.props.validStatus, statusId)
                              ? null
                              : Language.get("nc_mass_change_status_not_possible"),
                          isDisabled: !_.contains(this.props.validStatus, statusId),
                          action: () => this.setStatus(selectedReservationIds, status),
                      };
                  }),
              }
            : null;
        const moveItem =
            this.props.defaultStatus !== RC.STATUS.WAITING_LIST
                ? {
                      label: Language.get("nc_mass_change_move_reservations"),
                      isDisabled: selectedReservationIds.length === 0,
                      action: () => {
                          onMassChangeObjects(OBMC.MOVE);
                      },
                      classNames: ["icon", "massMoveObject"],
                  }
                : null;
        const copyAvailabilityItem =
            this.props.defaultStatus !== RC.STATUS.WAITING_LIST &&
            TemplateKind.equals(TemplateKind.AVAILABILITY, this.props.searchOptions.templateKind)
                ? {
                      label: Language.get("cal_reservation_action_button_copy"),
                      isDisabled: selectedReservationIds.length === 0,
                      action: () => {
                          onMassChangeObjects(OBMC.COPY_AVAILABILITY);
                      },
                      classNames: ["icon", "massMoveObject"],
                  }
                : null;
        const cancelReservations = (sendEmail) => {
            TimeEditAPI.okToCancelReservations(
                { reservationIds: selectedReservationIds },
                (res) => {
                    const canCancelAll = _.every(res.parameters[0], (status) => !status.details);
                    if (!canCancelAll) {
                        this.context.presentModal(
                            <div>
                                {
                                    <p>
                                        {Language.get(
                                            "cal_res_below_reservations_can_not_be_cancelled"
                                        )}
                                    </p>
                                }
                            </div>,
                            null,
                            Language.get("cal_res_below_cancel_reservations")
                        );
                        return;
                    }

                    const yesButton = {
                        title: Language.get("dialog_yes"),
                        cb: () => {
                            const calls = _.splitArray(
                                selectedReservationIds,
                                LIMIT_RESERVATION_DELETE
                            ).map((batch) => (done) => {
                                Reservation.cancel(
                                    batch,
                                    () => {
                                        done();
                                    },
                                    this.context.useNewReservationGroups
                                );
                            });
                            _.runSync(calls, () => {
                                if (sendEmail) {
                                    this.emailReservations(selectedReservationIds);
                                }
                                this.onUpdate([]);
                                if (this._clearSelection) {
                                    this._clearSelection();
                                }
                                if (this._find) {
                                    this._find();
                                }
                            });
                        },
                    };
                    const noButton = { title: Language.get("dialog_no") };
                    const buttons = [yesButton, noButton];
                    this.context.presentModal(
                        <div>
                            {
                                <p>
                                    {Language.get(
                                        "cal_res_below_do_you_wish_to_cancel_all",
                                        selectedReservationIds.length
                                    )}
                                </p>
                            }
                        </div>,
                        null,
                        Language.get("cal_res_below_cancel_reservations"),
                        buttons
                    );
                }
            );
        };

        const cancelSubmenu = [
            {
                label: Language.get("cal_func_res_delete"),
                isDisabled: selectedReservationIds.length === 0,
                action: () => {
                    cancelReservations(false);
                },
            },
            {
                label: Language.get("cal_func_res_delete_email"),
                isDisabled:
                    selectedReservationIds.length === 0 ||
                    !TimeEdit.isEmailActive ||
                    this.props.activeLayer !== 0,
                action: () => cancelReservations(true),
            },
        ];

        const autoObjectItem = {
            label: Language.get("nc_menu_item_automatic_object_selection"),
            isDisabled: selectedReservationIds.length === 0,
            action: () => {
                onMassChangeObjects(OBMC.ASSIGN);
            },
            classNames: ["icon", "massAssignObject"],
        };

        const examRequestItem =
            this.context.examComponentActive && this.state.hasExamId
                ? {
                      key: "reservationList.createExamRequest",
                      label:
                          this.state.hasExamValue && selectedReservationIds.length === 1
                              ? Language.get("nc_edit_exam_request")
                              : Language.get("nc_create_exam_request"),
                      isDisabled: selectedReservationIds.length < 1,
                      action: () => {
                          API.getReservationsByExtid(selectedReservationIds, (result) => {
                              this.context.createExamRequest(
                                  result,
                                  this.state.hasExamValue ? EXAM_MODE.EDIT : EXAM_MODE.CREATE
                              );
                          });
                      },
                  }
                : null;

        const result = [
            divider(),
            {
                label: Language.get("nc_mass_change_add_object"),
                isDisabled: selectedReservationIds.length === 0,
                action: () => {
                    onMassChangeObjects(OBMC.ADD);
                },
                classNames: ["icon", "massAddObject"],
            },
            {
                label: Language.get("nc_mass_change_remove_object"),
                isDisabled: selectedReservationIds.length === 0,
                action: () => {
                    onMassChangeObjects(OBMC.REMOVE);
                },
                classNames: ["icon", "massRemoveObject"],
            },
            {
                label: Language.get("nc_mass_change_replace_object"),
                isDisabled: selectedReservationIds.length === 0,
                action: () => {
                    onMassChangeObjects(OBMC.REPLACE);
                },
                classNames: ["icon", "massReplaceObject"],
            },
            moveItem,
            copyAvailabilityItem,
            autoObjectItem,
            divider(),
            {
                label: Language.get("nc_cal_func_res_edit_fields"),
                isDisabled:
                    selectedReservationIds.length > MAX_RESERVATIONS_MODIFY ||
                    selectedReservationIds.length === 0,
                action: () => {
                    this.editFields(selectedReservationIds);
                },
                classNames: ["icon", "editFields"],
                shortcut: TimeEdit.presentShortcut("mod+e"),
            },
            {
                key: "table_select_all",
                label: Language.get("cal_reservation_action_button_select_all"),
                action: () => {
                    callback(true);
                },
                classNames: ["icon", "selectAll"],
                shortcut: TimeEdit.presentShortcut("mod+a"),
            },
            {
                key: "entry.cancelMenu",
                label: Language.get("cal_func_res_delete"),
                isDisabled: selectedReservationIds.length === 0,
                submenu: cancelSubmenu,
            },
        ];
        if (statusItem) {
            result.push(divider());
            result.push(statusItem);
        }
        if (experimentModeMenu) {
            result.push(divider());
            result.push(experimentModeMenu);
        }
        if (examRequestItem) {
            result.push(divider());
            result.push(examRequestItem);
        }
        if (this.context.useNewReservationGroups) {
            result.push(divider());
            result.push({
                key: "list.createGroup",
                label: Language.get("nc_create_reservation_group"),
                isDisabled: selectedReservationIds.length === 0, // TODO: Perhaps disable if the selection already contains groups, since each reservation may only belong to one group
                action: () => {
                    const name = this.context.user.promptForGroupName
                        ? // eslint-disable-next-line no-alert
                          prompt(Language.get("nc_reservation_group_name"))
                        : "";
                    if (name === null) {
                        return;
                    }
                    API.createReservationGroupWithReservations(
                        name,
                        _.flatten(selectedReservationIds),
                        // eslint-disable-next-line no-shadow
                        (result) => {
                            this.onUpdate(selectedReservationIds, result);
                        },
                        (errorMessage) => Log.error(errorMessage)
                    );
                },
            });
        }
        return result.filter((item) => item !== null);
    },

    _setClearSelection(clearFunction) {
        this._clearSelection = clearFunction;
    },

    _setFindFunction(findFunction) {
        this._find = findFunction;
    },

    setStatus(selectedReservationIds, status) {
        const setStatus = () => {
            let numSuccessful = 0;
            const calls = _.splitArray(selectedReservationIds, TimeEditAPI.STATUS_BATCH_SIZE).map(
                (batch) => (done) => {
                    TimeEditAPI.setReservationStatus(batch, status.id, (result) => {
                        numSuccessful += result[0].filter((bool) => bool === true).length;
                        // Update progress?
                        done();
                    });
                }
            );
            _.runSync(calls, () => {
                if (numSuccessful === 0) {
                    Log.warning(Language.get("nc_edit_reservation_status_change_error"));
                    return;
                }
                if (numSuccessful < selectedReservationIds.length) {
                    Log.warning(
                        Language.get(
                            "nc_edit_reservation_status_change_x_of_y_complete",
                            numSuccessful,
                            selectedReservationIds.length
                        )
                    );
                } else {
                    Log.info(Language.get("nc_edit_reservation_status_change_complete"));
                }
                this.onUpdate(selectedReservationIds);
            });
        };

        TimeEditAPI.getPreferences("dismissedModalDialogs.confirm_mass_status_change", (value) => {
            if (!value || value === null || process.env.NODE_ENV === "development") {
                const buttons = [
                    { title: Language.get("dialog_cancel") },
                    { title: Language.get("nc_dialog_proceed"), cb: setStatus },
                ];
                this.context.presentModal(
                    <p>
                        {Language.get(
                            "nc_confirm_mass_status",
                            selectedReservationIds.length,
                            status.getStatusName()
                        )}
                    </p>,
                    "confirm_mass_status_change",
                    null,
                    buttons
                );
            } else {
                setStatus();
            }
        });
    },

    getSearchHelp() {
        if (this.props.getSearchHelp) {
            return this.props.getSearchHelp();
        }
        return (
            <div className="reservationSearchHelp">
                <table>
                    <tbody>
                        <tr>
                            <td>#</td>
                            <td>{Language.get("nc_search_help_reservation_id")}</td>
                        </tr>
                        <tr>
                            <td>##</td>
                            <td>{Language.get("nc_search_help_object_id")}</td>
                        </tr>
                        <tr>
                            <td>@</td>
                            <td>{Language.get("nc_search_help_changed_or_created_by")}</td>
                        </tr>
                        <tr>
                            <td>@@</td>
                            <td>{Language.get("nc_search_help_created_by")}</td>
                        </tr>
                        <tr>
                            <td>m:</td>
                            <td>{Language.get("nc_search_help_change_date")}</td>
                        </tr>
                        <tr>
                            <td>t:</td>
                            <td>{Language.get("nc_search_help_has_objects_of_type")}</td>
                        </tr>
                        <tr>
                            <td>f:</td>
                            <td>{Language.get("nc_search_help_has_reservation_text")}</td>
                        </tr>
                        <tr>
                            <td>p:</td>
                            <td>{Language.get("nc_search_help_has_placeholder_for_type")}</td>
                        </tr>
                        <tr>
                            <td>$:</td>
                            <td>{Language.get("nc_autopilot_id")}</td>
                        </tr>
                        <tr>
                            <td>g:</td>
                            <td>{Language.get("nc_search_help_group_name_or_id")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    },

    onUpdate(selectedReservationIds) {
        this.context.fireEvent(
            `reservationList${this.props.id}`,
            Macros.Event.RESERVATION_MADE_OR_MODIFIED,
            selectedReservationIds
        );
        this.props.refresh();
    },

    getCellLabels(reservation, columns, columnWidths) {
        let color;
        let textColor;
        let bold;

        if (
            process.env.NODE_ENV === "development" &&
            this.state.selectedObjectCheckSum !== null &&
            this.state.selectedIds.length === 1 &&
            this.state.selectedObjectCheckSum === reservation.objectCheckSum &&
            reservation.id !== this.state.selectedIds[0]
        ) {
            color = "rgb(245, 245, 245)"; //"#d6eaff"; //"#c5e1ff";
        }

        const labels = columns.map((column, i) => {
            if (!reservation) {
                return "";
            }
            if (column.name === Language.get("cal_reservation_list_column_id")) {
                return reservation.id;
            }
            const width = columnWidths[i];
            if (column.name === Language.get("cal_reservation_list_column_type_all")) {
                if (!reservation.objects) {
                    return "";
                }
                const rootId = TimeEdit.rootType !== undefined ? TimeEdit.rootType : 0;
                return reservation.objects
                    .filter((object) => object.type.id === rootId)
                    .map((object) => {
                        if (object.fields && object.fields[0].values) {
                            return object.fields[0].values[0];
                        }
                        return "-";
                    })
                    .join(", ");
            }
            if (column.id > TYPE_COLUMN_OFFSET && column.id < FIELD_COLUMN_OFFSET) {
                if (!reservation.objects) {
                    return "";
                }
                const typeId = column.id - TYPE_COLUMN_OFFSET;
                return reservation.objects
                    .filter((object) => object.type.id === typeId)
                    .map((object) => {
                        if (object.fields && object.fields[0].values) {
                            return object.fields[0].values[0];
                        }
                        return "-";
                    })
                    .join(", ");
            }
            if (column.id > FIELD_COLUMN_OFFSET) {
                if (!reservation.fields) {
                    return "";
                }
                const fieldId = column.id - FIELD_COLUMN_OFFSET;
                return reservation.fields
                    .filter((field) => field.id === fieldId)
                    .map((field) => field.values.join(", "));
            }
            if (column.name === Language.get("cal_reservation_list_column_field_all")) {
                if (!reservation.fields) {
                    return "";
                }
                return reservation.fields
                    .map((field) => (field.values ? field.values.join(", ") : null))
                    .filter((val) => val !== "")
                    .join(", ");
            }
            let format;
            const startTime = reservation.begin ? new MillenniumDateTime(reservation.begin) : null;
            const endTime = reservation.end ? new MillenniumDateTime(reservation.end) : null;
            // eslint-disable-next-line no-magic-numbers
            const between = startTime && endTime ? startTime.daysBetween(endTime) : 2;
            const isSameDay = between === 0 || (between === 1 && endTime.isMidnight());
            if (
                column.name === Language.get("cal_reservation_list_column_date") &&
                reservation.begin
            ) {
                if (!isSameDay) {
                    // Different start and end date
                    format = Language.getDateFormat("date_f_m_d");
                    const start = SimpleDateFormat.format(startTime, format);
                    const end = SimpleDateFormat.format(
                        endTime,
                        Language.getDateFormat("date_f_m_d_end")
                    );
                    if (start !== end) {
                        return `${start}-${end}`;
                    }
                    return start;
                }
                format = Language.getDateFormat("date_f_yy_mm_dd");
                return SimpleDateFormat.format(startTime, format);
            }
            if (
                column.name === Language.get("cal_reservation_list_column_time") &&
                reservation.begin
            ) {
                return `${this.getTime(startTime, width, false)}-${this.getTime(
                    endTime,
                    width,
                    true
                )}`;
            }
            if (column.name === Language.get("cal_res_side_user_time_zone")) {
                return reservation.timezone || "";
            }
            if (column.name === Language.get("cal_reservation_list_column_length")) {
                const length = reservation.length
                    ? reservation.length
                    : reservation.end - reservation.begin;
                if (length === 0 || isNaN(length)) {
                    return "0:00";
                }
                const hours = Math.floor(length / TC.SECONDS_PER_HOUR);
                const minutes = Math.floor((length % TC.SECONDS_PER_HOUR) / TC.SECONDS_PER_MINUTE);
                // eslint-disable-next-line no-magic-numbers
                return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
            }
            if (column.name === Language.get("cal_reservation_list_column_status")) {
                if (!reservation.status) {
                    return ReservationStatus.C_CONFIRMED.getStatusName(false);
                }

                let status = reservation.status;
                if (
                    _.some(
                        status,
                        (item) =>
                            item.status === RC.STATUS.REQUESTED ||
                            item.status === RC.STATUS.REJECTED
                    )
                ) {
                    status = status.filter((item) => item.status !== RC.STATUS.INCOMPLETE);
                }
                return status
                    .map((item) => ReservationStatus.statusForId(item.status).getStatusName(false))
                    .join(", ");
            }
            if (column.name === Language.get("cal_reservation_list_column_created")) {
                let created = "";
                if (reservation.created) {
                    const createdTime = new MillenniumDateTime(reservation.created || 0);
                    created = this.getLongDate(createdTime, width);
                }
                const createdBy = reservation.createdby ? `, ${reservation.createdby}` : "";
                return created + createdBy;
            }
            if (column.name === Language.get("cal_reservation_list_column_modified")) {
                let modified = "";
                if (reservation.modified) {
                    const modifiedTime = new MillenniumDateTime(reservation.modified || 0);
                    modified = this.getLongDate(modifiedTime, width);
                }
                const modifiedBy = reservation.modifiedby ? `, ${reservation.modifiedby}` : "";
                return modified + modifiedBy;
            }
            if (column.name === Language.get("cal_reservation_list_column_cancelled")) {
                if (reservation.cancelled) {
                    const cancelledTime = new MillenniumDateTime(reservation.cancelled || 0);
                    return this.getLongDate(cancelledTime, width);
                }
                return "";
            }
            if (column.name === Language.get("cal_reservation_list_column_week")) {
                if (!startTime || !endTime) {
                    return "";
                }
                return Language.formatWeekTableCell(
                    startTime,
                    endTime,
                    this.context.customWeekNames
                );
            }
            if (column.name === Language.get("cal_reservation_list_column_week_day")) {
                if (!startTime || !endTime) {
                    return "";
                }
                const start = SimpleDateFormat.format(startTime, "EE");
                const end = SimpleDateFormat.format(endTime, "LL");
                if (start === end) {
                    return start;
                }
                return `${start} - ${end}`;
            }

            if (column.name === Language.get("nc_tags_title")) {
                return reservation.tags ? reservation.tags.join(", ") : "";
            }

            if (column.name === Language.get("nc_mass_change_collisions")) {
                const col = this.getCollisionsFor(reservation.id);
                if (!col || col.collidingReservations.length === 0) {
                    if (col && col.collidingInfoReservations.length > 0) {
                        return INFO_COLLISION;
                    }
                    return "";
                }
                return `${col.collidingReservations.length}`;
            }

            if (column.name === Language.get("nc_reservation_exception_title")) {
                if (!reservation.memberExceptionCount) {
                    return "";
                }
                return `${reservation.memberExceptionCount}`;
            }

            if (column.name === Language.get("nc_reservation_group_list_title")) {
                if (!reservation.group) {
                    return "";
                }
                return reservation.group.name
                    ? reservation.group.name
                    : reservation.group.extid
                    ? reservation.group.extid
                    : reservation.group.id;
            }

            return "";
        });

        return { labels, color, textColor, bold };
    },

    render() {
        return (
            <GenericList
                {...this.props}
                progress={this.state ? this.state.progress : null}
                getDataMenuItems={this.getReservationMenuItems}
                collisions={this.state ? this.state.collisions : []}
                onSelectionChanged={this.onSelectionChanged}
                settingsToSave={SETTINGS_TO_SAVE}
                getCellLabels={this.getCellLabels}
                isSortable={this.isSortable}
                find={this.findReservations}
                onMassChange={this.onMassChange}
                setClearSelection={this._setClearSelection}
                setFindFunction={this._setFindFunction}
                getSearchHelp={this.getSearchHelp}
            />
        );
    },
});

module.exports = ReservationTypeList = LayerComponent.wrap(ReservationTypeList);
